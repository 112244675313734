import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import Utils from "../../Utils";
import classes from "./SignUp.module.css";
import { createUser, verifyUser, resendCode, forgotPassword, logout } from '../../modules/Users/UsersActions';
import signUpbackgroundImage from '../../assets/images/signup-background.svg';
import signUpBackgroundStars from '../../assets/images/signup-background-stars.svg';
import logoImage from "../../assets/bookalooza-logo.png";
import logoWithTagline from "../../assets/images/tagline.png";
import OTPForm from "./OTPForm";
import InfoForm from "./InfoForm";
import Consts from "../../Consts";
import { useDispatch } from "react-redux";

function otpVerification(data, history, postSignUpProcess) {
    return verifyUser(data).then((response) => {
        if (response && response?.data?.success) {
            postSignUpProcess();
        } else {
            throw {
                msg: 'Entered OTP is wrong'
            };
        }
    });
}

const infoConfig = {
    heading: "Sign Up",
    subHeading: "Hey! Enter your details to create your account.",
    fullname: true,
    phoneNumber: true,
    schoolName: true
}


export function SignupForm({ postSignUpProcess, goToLogin }) {
    const history = useHistory();
    const [userInfo, setUserInfo] = useState(null);
    const [isEmailValid, setEmailValid] = useState(true);
    const [otpError, setOTPError] = useState('');

    return (
        <div className={classes.leftMainBox}>
            <div className={classes.logoImage}>
                <img src={logoImage} className={classes.bulbImage} alt="Logo" />
                <img src={logoWithTagline} className={classes.tagline} alt="Tag line" />
            </div>
            <div className={classes.mainBox}>
                {!userInfo && <InfoForm isEmailValid={isEmailValid}
                    goToLogin={goToLogin}
                    onSubmit={e => {
                        createUser(e).then(d => {
                            if (d.success) {
                                setUserInfo(e);
                            } else if (d.errorCode === "already_exist") {
                                setEmailValid(false);
                            }
                        });
                    }}
                    config={infoConfig}
                />}
                {userInfo && <OTPForm
                    onCreate={e =>
                        otpVerification({
                            ...e,
                            ...userInfo
                        }, history, postSignUpProcess).then(e => setOTPError(''))
                            .catch(err => setOTPError(err?.msg || 'Unknown'))
                    }
                    resendOTP={e => resendCode(userInfo)}
                    onBack={e => setUserInfo(null)}
                    errMsg={otpError}
                />}
            </div>
        </div>
    )
}




function SignUp(props) {

    const history = useHistory();
    const dispatch = useDispatch();

    function postSignUpProcess() {
        history.push({
            pathname: Consts.BASE_URL || '/',
            state: {
                from: history.location.pathname
            }
        });
    }

    function goToLogin() {
        history.push({
            pathname: `${Consts.BASE_URL}/sign-in`,
            state: { ...history.location?.state },
        });
    }

    useEffect(() => {
        if (Utils.isUserLoggedIn()) {
            logout(history, dispatch);
        }
    }, []);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.rightMainBox}>
                <img src={signUpbackgroundImage} className={classes.backgroundImage} />
                <img src={signUpBackgroundStars} className={classes.backgroundStars} />
            </div>
            <SignupForm postSignUpProcess={postSignUpProcess} goToLogin={goToLogin} />
        </div>
    );
}

export default SignUp;