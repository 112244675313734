import React, { useEffect, useRef, useState } from "react";
import { fetchAllPodcasts, fetchPodcastCount } from "./PodcastsAction";
import { useDispatch } from "react-redux";
import classes from "./Podcasts.module.css";
import MainFooter from "../../components/MainFooter/MainFooter";
import PodcastListing from "./PodcastListing/PodcastListing";
import TabControl from "../../components/TabControl/TabControl";
import WrapperContainer from "../../components/Container/WrapperContainer";
import banner from "../../assets/banner-podcasts.png";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import nextLabelIcon from "../../assets/images/pagination-next-icon.svg";
import previousLabelIcon from "../../assets/images/pagination-previous-icon.svg";
import ReactPaginate from "react-paginate";
import HelmetProvider from "../../components/Helmet/Helmet";

const options = [
  { title: "Voice of Leadership", action: "voiceLeadership" },
  { title: "Star Authors", action: "starAuthors" },
  { title: "Brand Videos", action: "generalVideos" },
];

const pageSize = 20;

function Podcasts() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("voiceLeadership");
  const [pageNo, setPageNo] = useState(1);
  const listRef = useRef(null);
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    fetchAllPodcasts(dispatch, {
      where: { category: { $regex: activeTab, $options: "i" } },
      offset: (pageNo - 1) * pageSize,
      limit: pageSize,
      sortBy: "updateTime DESC",
    });
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    };
  }, [activeTab, pageNo]);

  useEffect(() => {
    fetchPodcastCount({ category: { $regex: activeTab, $options: "i" } })
      .then(res => {
        let data = res.data;
        if (data.success) {
          setTotalPages(Math.ceil(data.data.count / pageSize));
        }
      })
  }, [activeTab]);

  function handlePageChange(e) {
    setPageNo(e.nextSelectedPage + 1);
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.banner}>
          <img src={banner} alt="banner" />
          <div className={classes.btnWrapper}>
            <PrimaryButton
              onClick={() =>
                window.open("https://forms.gle/e5ijqiihBKqSA1XH8", "_blank")
              }
            >
              Book Your Space Now!
            </PrimaryButton>
          </div>
          <div className={classes.tabsWrapper}>
            <TabControl
              options={options}
              onTabChange={(a) => setActiveTab(a)}
            />
          </div>
        </div>
        <WrapperContainer ref={listRef}>
          <PodcastListing />
          {
            totalPages > 0 && <ReactPaginate
              key={"Pagination"}
              nextLabel={
                <img
                  className={
                    pageNo === totalPages + 1
                      ? classes.disabledButton
                      : ""
                  }
                  src={nextLabelIcon}
                  alt="Next"
                />
              }
              onClick={handlePageChange}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={totalPages}
              previousLabel={
                <img
                  className={pageNo === 2 ? classes.disabledButton : ""}
                  src={previousLabelIcon}
                  alt="Pervious"
                />
              }
              pageClassName={classes.pageItem}
              pageLinkClassName={classes.pageLink}
              disableInitialCallback
              previousClassName={classes.pageItemPrevious}
              previousLinkClassName={classes.pageLink}
              nextClassName={classes.pageItem}
              nextLinkClassName={classes.pageLink}
              breakLabel="..."
              breakClassName={classes.break}
              breakLinkClassName="page-link"
              containerClassName={classes.pagination}
              activeClassName={classes.pageItemActive}
            />
          }
        </WrapperContainer>

      </div>
      <MainFooter />
    </>
  );
}

export default Podcasts;
