import React from "react";
import classes from "./TextArea.module.css";
import Utils from "../../Utils";

function TextArea({
  name,
  onChange,
  rows = 10,
  placeHolder,
  value,
  disabled,
  className,
}) {
  return (
    <textarea
      name={name}
      rows={rows}
      value={value}
      onChange={onChange}
      placeholder={placeHolder}
      className={Utils.getClasses(classes.primaryTextArea, className)}
      disabled={disabled}
    ></textarea>
  );
}

export default TextArea;
