import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../Users/UsersActions";
import Card from "../../components/Card/Card";
import classes from "./ConfirmUser.module.css";
import ModalDialog from "../../components/Dialog/Dialog";
import Input from "../../components/Input/Input";
import Utils from "../../Utils";
import indianFlag from "../../assets/flags/IN.png";
import ImageDropDown from "../../components/ImageDropDown/ImageDropDown";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import logoImage from "../../assets/bookalooza-logo.png";
import logoWithTagline from "../../assets/images/tagline.png";

const country = [
  {
    country: "India",
    countryCode: "+91",
    imageSrc: indianFlag,
  },
];

const disabledLocations = ["/sign-up", "/sign-in", "/forgot-password"];

function ConfirmUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = useHistory();
  const user = useSelector((state) => state.user.user || {});
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userId: "",
    phoneNumber: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [errorObj, setErrorObj] = useState({
    fullName: "",
    userId: "",
    rePassword: "",
    password: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (user) {
      const name = user?.fullName?.split(" ");
      setUserData({
        firstName: name?.[0] || "",
        lastName: name?.[1] || "",
        userId: user?.email || "",
        phoneNumber: user?.phoneNumber || "",
      });
    }
  }, []);

  const onInputChange = (e) => {
    let { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const checkValidEmail = (value) => {
    if (value) {
      setErrorObj((prevState) => ({
        ...prevState,
        userId: Utils.validateEmail(value).message,
      }));
    }
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setErrorObj((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "Please enter first name.";
          }
          break;
        case "lastName":
          if (!value) {
            stateObj[name] = "Please enter last name.";
          }
          break;
        case "userId":
          if (!value) {
            stateObj[name] = "Please enter email.";
          }

          break;
        case "phoneNumber":
          if (!value) {
            stateObj[name] = "Please enter your phone number.";
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  const checkValidPhoneNumber = (value) => {
    if (value && value.toString().length !== 10) {
      setErrorObj((prevState) => ({
        ...prevState,
        phoneNumber: "Mobile number must be 10 digits.",
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const data = {
      fullName: `${userData.firstName} ${userData.lastName}`,
      phoneNumber: userData.phoneNumber,
      email: userData.userId,
    };

    updateProfile(data, "", dispatch).then((res) => {
      if (res.data.success) {
        history.push("/");
      }
    });
  };

  return (
    <>
      <div className={classes.main}>
        <div className={classes.mainBox}>
          <Card backColor="white" borderRadius="24px">
            <div className={classes.container}>
              <div className={classes.logoImage}>
                <img src={logoImage} className={classes.bulbImage} alt="Logo" />
                <img
                  src={logoWithTagline}
                  className={classes.tagline}
                  alt="Tag line"
                />
              </div>
              <div className={classes.heading}>
                <h2>Hi! {user?.fullName || ""}</h2>
                <p>Your username: {user?.userId}</p>
              </div>
              <div className={classes.content}>
                <div className={classes.nameInputsWrapper}>
                  <Input
                    placeholder="First name"
                    name="firstName"
                    value={userData.firstName}
                    onChange={(e) => onInputChange(e)}
                  />
                  <Input
                    placeholder="Last name"
                    name="lastName"
                    value={userData.lastName}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                {errorObj.firstName && (
                  <span className={classes.errorMsg}>{errorObj.firstName}</span>
                )}
                {errorObj.lastName && (
                  <span className={classes.errorMsg}>{errorObj.lastName}</span>
                )}
                <Input
                  placeholder="Email"
                  name="userId"
                  onChange={(e) => onInputChange(e)}
                  value={userData.userId}
                  onBlur={(e) => checkValidEmail(e.target.value)}
                />
                {!isEmailValid && (
                  <p className={classes.errorMsg}>Email already exists.</p>
                )}
                {errorObj.userId && (
                  <span className={classes.errorMsg}>{errorObj.userId}</span>
                )}
                <div className={classes.numberFieldWrapper}>
                  <ImageDropDown
                    className={classes.countryDropDown}
                    options={country}
                  />
                  <span className={classes.divider}></span>
                  <Input
                    type="number"
                    placeholder="Phone Number"
                    onChange={(e) => onInputChange(e)}
                    value={userData.phoneNumber}
                    name="phoneNumber"
                    onKeyDown={(e) => {
                      ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault();
                    }}
                  />
                </div>
                {errorObj.phoneNumber && (
                  <span className={classes.errorMsg}>
                    {errorObj.phoneNumber}
                  </span>
                )}
                <div
                  className={classes.actions}
                  style={{ justifyContent: "center" }}
                >
                  <PrimaryButton
                    onClick={(e) => {
                      if (checkValidPhoneNumber(userData.phoneNumber)) {
                        handleSubmit();
                      }
                    }}
                    style={{
                      width: "100%",
                      height: "2.5rem",
                      fontSize: "0.875rem",
                    }}
                    disabled={
                      !userData.firstName ||
                      !userData.lastName ||
                      !userData.userId ||
                      errorObj.userId ||
                      !userData.phoneNumber
                    }
                  >
                    Next
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ConfirmUser;
