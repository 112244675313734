import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { CategorySelect } from './CategoryDialog';
import { useState } from "react";
import Container from "./components/Container";
import { useBookContextHandler } from "../BookContext";
import { useFabricCanvas } from "../FabricCanvas";
import { placeBackground } from "../objectUtils";

let fabric;

export default function ImageWidget({ visible, imageData, title, hasCategory = true, showAllItemes, getCategory, setOpen, taggablePanel }) {
    const [selectedValue, setSelectedValue] = useState(0);
    const [frontPage, setFrontPage] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();
    const canvas = useFabricCanvas();

    useEffect(() => {
        fabric = window.global.fabric;
    }, []);

    useEffect(() => {
        (async function () {
            let categ = await getCategory?.(categories, selectedValue) || categories[selectedValue];
            if (categ) {
                setCategory(categ);
            }
        })();
    }, [selectedValue, categories]);

    const [book] = useBookContextHandler(ctx => ({
        'page:mounted': function ({ target }) {
            setFrontPage(target.isFrontPage());
        }
    }), [imageData, canvas]);

    useEffect(() => {
        if (imageData) {
            setCategories(imageData.map((i, idx) => ({
                ...i,
                label: i.name,
                value: idx
            })));
        }
    }, [imageData, frontPage])

    useEffect(() => {
        let theme = book?.getTheme();
        if (theme && categories[selectedValue]?.id !== 'all') {
            let idx = categories.findIndex(c => c.name === theme);
            if (idx !== -1) {
                setSelectedValue(idx);
            }
        }
    }, [book, categories])

    let images = category?.items || [];
    if (!showAllItemes) {
        images = images.filter(item => Boolean(item.isFrontCover) === frontPage);
    }

    return <Container visible={visible} title={title}>
        {
            hasCategory &&
            <CategorySelect categories={categories} value={selectedValue}
                style={{ marginBottom: "0.6rem" }}
                categoryKey="name"
                onChange={(e, idx) => setSelectedValue(idx)}
            />
        }
        <div className="scroll-vertical scroll-style" >
            <div>
                <Grid container spacing={1} style={{ margin: '0', paddingRight: '12px', marginBottom: "3rem" }}>
                    {
                        images.length ?
                            images.map((img, idx) =>
                                <Grid item xs={6} key={idx} style={{ display: "flex", justifyContent: 'center' }}>
                                    <div className="image-item-thumb-wrap" style={{ width: '100%' }} onClick={() => {
                                        placeBackground(canvas, img).then(e => {
                                            if (!taggablePanel) {
                                                setOpen?.(false);
                                            }
                                        });
                                    }}>
                                        <img className="image-item-thumb" src={img.thumbPath} alt="" />
                                    </div>
                                </Grid>
                            ) : <div className="emptyMessage">Sorry, we are out of themes.</div>
                    }
                </Grid>
            </div>
        </div>
    </Container>;
}