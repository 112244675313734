import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { Helmet } from "react-helmet";

const descriptions = {
  base: "Bookalooza allows young children to write, publish and earn a 10% royalty through selling their books. We provide in-built designs and AI features to help you write your dream book.",
  blogs: "Check out our blogs for tips and new learnings from the best writings.",
  store: "Discover amazing stories| Support Star Authors by purchasing their incredible books today!",
  newbook: "Sign up to create your account and write your book free of cost.",
  podcasts: "Get a chance to be featured on our Star Author Podcast. Book your space now!"
}

const titles = {
  base: "Bookalooza- Write, Publish and Earn through Books",
  blogs: "Our Blogs - Bookalooza",
  store: "Bookalooza Bookstore",
  newbook: "Create Books - Bookalooza",
  podcasts: "Star Author Podcast - Bookalooza"
}

function HelmetProvider() {
  const history = useHistory();
  const [title, setTitle] = useState(titles.base);
  const [description, setDescription] = useState(descriptions.base);

  useEffect(() => {
    const pathname = history.location.pathname.split("/");

    if(titles[pathname[1]] && descriptions[pathname[1]]) {
      setTitle(titles[pathname[1]]);
      setDescription(descriptions[pathname[1]]);
    } else {
      setTitle(titles.base);
      setDescription(descriptions.base);
    }

  }, [history.location.pathname])
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": ${title},
          "url": ${window.location.href}
        }`}
      </script>
    </Helmet>
  );
}

export default HelmetProvider;
