import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuote, fetchQuotes } from "./AIHelpAction";
import { useBookContext } from "../BookContext";
import classes from "./AIHelp.module.css";
import { useFabricCanvas } from "../FabricCanvas";
import AIData from "../../../components/AIData/AIData";

export default function Quotes() {
  const quotes = useSelector((s) => s.aiHelp.quotes);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();

  const canvas = useFabricCanvas();
  const bookCtx = useBookContext();
  useEffect(() => {
    if (!quotes?.length) {
      fetchQuotes(dispatch);
    } else {
      const quote =
        quotes.find((q) => q.theme === bookCtx.getTheme()) || quotes[0];
      fetchQuote(quote.id).then(setSelected);
    }
  }, [quotes]);

  const themeOptions =
    quotes?.map((q) => ({ label: q.theme, value: q.id })) || [];
  return (
    <div className={classes.container}>
      {/* <Select
        options={themeOptions}
        value={themeOptions.find((t) => t.value === selected?.id)}
        onChange={(e) => fetchQuote(e.value).then(setSelected)}
      /> */}
      <div className={classes.innerMain}>
      {selected?.values?.map((q) => (<AIData text={q} /> ))}
      </div>
    </div>
  );
}
