import React, { useEffect, useRef, useState } from "react";
import classes from "./StoreSection.module.css";
import Utils from "../../../Utils";
import BookItem from "../../../components/BookItem/BookItem";
import Carousel from "react-elastic-carousel";
import { useHistory } from "react-router";

const carouselAutoPlaySpeed = 5000

function StoreSection({ books, heading, theme }) {
  const carouselRef = useRef();
  const history = useHistory();
  const [screenSize, setScreenSize] = useState(0);

  let resetTimeout;

  const totalPages = screenSize < 577 ? 2 : screenSize > 1200 ? 5 : 3;

  const onCurrentChange = (current, index) => {
    clearTimeout(resetTimeout);
    resetTimeout = setTimeout(() => {
      carouselRef.current?.goTo?.(0);
    }, 8000);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  return (
    <div className={theme === "purple" ? classes.main : classes.orangeMain}>
      <div className={theme === "purple" ? classes.heading : classes.orangeHeading}>
        <p>{heading}</p>
      </div>
      <div className={classes.booksContainer}>
        <Carousel
          itemsToShow={totalPages}
          itemsToScroll={1}
          itemPadding={[16, 16, 16, 16]}
          onNextEnd={onCurrentChange}
          disableArrowsOnEnd={true}
          pagination={false}
          autoPlaySpeed={carouselAutoPlaySpeed}
          enableAutoPlay
          ref={carouselRef}
          enableSwipe={true}
          enableMouseSwipe={true}
          showArrows={screenSize < 769 ? false : true}
        >
          {books ? (
            books.map((book, idx) => (
              <BookItem
                key={idx}
                style={{
                  backgroundColor: theme === "purple" ? "var(--app-dark)" : "rgb(242, 110, 20)",
                  padding: "0.8rem",
                }}
                starColor={theme === "purple" ? "rgb(74, 182, 186)" : "rgb(74, 182, 186)"}
                book={book}
                onClick={() => {
                  Utils.showBookDetails(history, book.urlSlag);
                }}
              />
            ))
          ) : (
            <div>No books found</div>
          )}
        </Carousel>
      </div>
    </div>
  );
}

export default StoreSection;
