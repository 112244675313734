import React, { useEffect, useRef, useState } from 'react';
import classes from './ContactUs.module.css'
import MainFooter from '../../components/MainFooter/MainFooter';
import bookAloozaLogo from "../../assets/campaign/Bookalooza-New-Logo_White.png"
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Map from '../../components/Map/Map';
import axios from '../../../axios';
import BackButton from '../../components/Buttons/BackButton';

function ContactUs() {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState()
    const [message, setMessage] = useState();
    const [hasError, setHasError] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    useEffect(() => {
        if (name && email && phone && message) {
            setFormMessage('');
            setHasError(false);
        } else {
            setHasError(true);
        }
    }, [name, phone, email, message]);


    async function submitContactUsFrom() {
        let emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        setFormMessage('');
        setHasError(false);
        if (!name) {
            setHasError(true);
            setFormMessage("Please enter your name.");
            setTimeout(e => setFormMessage(''), 3000);
            return false;
        }
        if (!phone) {
            setHasError(true);
            setFormMessage("Please enter your phone.");
            setTimeout(e => setFormMessage(''), 3000);
            return false;
        } else if (phone.length < 10) {
            setHasError(true);
            setFormMessage("Please enter valid phone.");
            setTimeout(e => setFormMessage(''), 3000);
            return false;
        }

        if (!email) {
            setHasError(true);
            setFormMessage("Please enter your email.");
            setTimeout(e => setFormMessage(''), 3000);
            return false;
        } else if (!emailRegex.test(email)) {
            setHasError(true);
            setFormMessage("Please enter valid email.");
            setTimeout(e => setFormMessage(''), 3000);
            return false;
        }
        if (!message) {
            setHasError(true);
            setFormMessage("Please enter your message.");
            setTimeout(e => setFormMessage(''), 3000);
            return false;
        }
        setSubmitting(true);
        const actionUrl = `/designer/contactus`;
        await axios({
            url: actionUrl,
            method: "POST",
            data: {
                "name": name,
                "phoneNo": phone,
                "email": email,
                "message": message,
            }
        }).then(function (response) {
            if (response.status) {
                setHasError(false);
            }
            setName('')
            setPhone('')
            setEmail('')
            setMessage('')
            setFormMessage("Thank you! Our team will contact you soon.");
            setTimeout(e => setFormMessage(''), 3000);

        }).finally(function () {
            // Remove class after request is complete
            setSubmitting(false);
        })
    }

    return (
        <>
            <div className={classes.main}>
                <div className={classes.backButtonWrapper}>
                    <BackButton className={classes.backButton} />
                </div>
                <div className={classes.container}>
                    <div className={classes.contactDetails}>
                        <div className={classes.logoWrapper}>
                            <div className={classes.logo}>
                                <img className={classes.bulb} src={bookAloozaLogo} alt="bookaloozaLogo" />
                            </div>
                        </div>
                        <h1 className={classes.headingText}>Contact us</h1>
                        <div className={classes.contactWrapper}>
                            <div>
                                <p className={classes.contactUsLabel}>Call us</p>
                                <p className={classes.phone}>+91 8799721408</p>
                                <p className={classes.phone}>+91-11-43776600</p>
                            </div>
                            <div>
                                <p className={classes.contactUsLabel}>Email id</p>
                                <p><a className={classes.mail} href="mailto:info@bookalooza.com">info@bookalooza.com</a></p>
                            </div>
                            <div>
                                <p className={classes.contactUsLabel}>Reach us</p>
                                <p className={classes.address}>Orange Vtech Pvt Ltd,</p>
                                <p className={classes.address}>9 Daryaganj, New Delhi-110002 (India)</p>
                            </div>
                        </div>
                        <div className={classes.googleMapWrapper}>
                            <p className={classes.contactUsLabel}>Locate on map</p>
                            <Map lat={28.646130} lng={77.243439} zoom={14} text="Orange Vtech Pvt Ltd" />
                        </div>
                    </div>
                    <div className={classes.formSection}>
                        <div className={classes.formWrapper}>
                            <h1 className={classes.heading}>Get In Touch With Us</h1>
                            <div className={classes.phoneAndEmailWrapper}>
                                <input className={`${classes.input} ${classes.nameInput}`} type="text" placeholder='Name' onChange={(e) => setName(e.target.value)} value={name} />
                            </div>
                            <div className={classes.phoneAndEmailWrapper}>
                                <input className={classes.input} type="number" placeholder='Phone' onChange={(e) => setPhone(e.target.value)} value={phone} />
                                <input className={classes.input} type="email" placeholder='Email' onChange={(e) => setEmail(e.target.value)} value={email} />
                            </div>
                            <textarea className={classes.messageContainer} placeholder="Write your message." id="" cols="30" rows="10" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
                            <span className={`${classes.formMessage} ${hasError ? classes.error : classes.success}`}>{formMessage}</span>
                            <PrimaryButton
                                disabled={submitting || hasError}
                                onClick={submitContactUsFrom}
                            >Send</PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
            <MainFooter />
        </>
    )
}

export default ContactUs