import React from "react";
import classes from "./AboutUs.module.css";
import MainFooter from "../../components/MainFooter/MainFooter";
import BackButton from "../../components/Buttons/BackButton";
import { useHistory } from "react-router";
import bookAloozaLogo from "../../assets/Bookalooza-logo-blackText.png";
import bookAnimation from "../../assets/aboutUsAnimation/boo-animation.json";
import Lottie from "react-lottie-player";
import arrowAnimation from "../../assets/aboutUsAnimation/arrow-animation.json";
import peopleAnimation from "../../assets/aboutUsAnimation/people-animation.json";

const content = [
  "Bookalooza is a worldwide platform that empowers those who believe that words can have an influential impact. This platform enables writers to convert their stories and thoughts into books that people can connect with.",
  "Bookalooza makes self-publishing a book easier with our free publishing platform. Our creative publishing program helps you publish a book. We offer the best way to self-publish books with lots of in-built templates and eye-catching covers that accurately represent the content of your publication.",
  "Our goal is to provide you with the support and resources you need to make your publishing dream a reality. Bookalooza gives you full control over the entire publishing process, from editing and formatting to cover design, from marketing to earning royalties. We review the content before publishing to check if any unethical content is uploaded.",
  "Our team of experienced professionals is committed to provide the highest level of service and support to ensure that your book stands out in a crowded marketplace.",
];

function AboutUs() {
  const history = useHistory();
  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.backButtonWrapper}>
          <BackButton className={classes.backButton} />
        </div>
        <div className={classes.container}>
          <div className={classes.contentWrapper}>
            <div className={classes.headingAndDescriptionWrapper}>
              <h1 className={classes.heading}>About Us</h1>
              <p className={classes.description}>{content?.[0]}</p>
            </div>
            <div className={classes.logoWrapper}>
              <img
                className={classes.bulb}
                src={bookAloozaLogo}
                alt="bookaloozaLogo"
              />
            </div>
          </div>

          <div className={classes.orangeWrapper}>
            <div className={classes.animationContainer}>
              <div className={classes.animationWrapper}>
                <Lottie
                  loop
                  animationData={bookAnimation}
                  play
                  style={{ width: "100%", height: "80%" }}
                />
              </div>
            </div>
            <div className={classes.descriptionWrapper}>
              <p>{content?.[1]}</p>
            </div>
          </div>

          <div className={classes.contentWrapper}>
            <div className={classes.headingAndDescriptionWrapper}>
              <p className={classes.description}>{content?.[2]}</p>
            </div>
            <div className={classes.logoWrapper}>
              <Lottie
                loop
                animationData={arrowAnimation}
                play
                style={{ width: "100%", height: "80%" }}
              />
            </div>
          </div>

          <div className={classes.purpleWrapper}>
            <div className={classes.descriptionWrapper}>
              <p>{content?.[3]}</p>
            </div>
            <div className={classes.animationContainer}>
              <div className={classes.animationWrapper}>
                <Lottie
                  loop
                  animationData={peopleAnimation}
                  play
                  style={{ width: "100%", height: "80%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
}
export default AboutUs;
