import React from 'react'
import classes from './LibraryBookCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHeart, faStar, faXmark } from '@fortawesome/free-solid-svg-icons';

function getFontThumbUrl(book) {
    return `${book.frontThumbURL}?${book.updateDate}`;
}

function LibraryBookCard({ book, onClick, style, onDelete }) {
    return (
        <div className={classes.main} onClick={onClick} style={style}>
            <div className={classes.imageWrapper}>
                <img className={classes.bookImage} src={getFontThumbUrl(book)} alt="" />
                {onDelete && <div className={classes.deleteIcon} onClick={(e) => {
                    e.stopPropagation();
                    onDelete()
                }
                }>
                    <FontAwesomeIcon icon={faXmark} />
                </div>}
            </div>
            <div className={classes.bookDetailsWrapper}>
                <h1 className={classes.bookName}>{book?.title || "Untitled"}</h1>
                <p className={classes.authorName}>By {book.author}</p>

                {
                    book?.status === 'PUBLISHED' &&
                    <div className={classes.ratingsLikesViews}>
                        <div>
                            <div className={classes.ratingsLikesViewsContent}>{book.likes || 0} <span className={classes.heartImage}><FontAwesomeIcon icon={faHeart} /></span></div>
                        </div>
                        <div>
                            <div className={classes.ratingsLikesViewsContent}>{book.views || 0} <span className={classes.eyeImage}><FontAwesomeIcon icon={faEye} /></span></div>
                        </div>
                        <div className={classes.ratings}>
                            <div className={classes.ratingsLikesViewsContent}>
                                {Math.ceil(book.overallRating) || 0}
                                <span className={classes.starImage}><FontAwesomeIcon icon={faStar} /></span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default LibraryBookCard