import React, { useState } from 'react';
import Utils from '../../Utils';
import classes from "./ProductPictures.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHeart } from '@fortawesome/free-solid-svg-icons';

function ProductPictures(props) {
    const [pictureIndex, setPictureIndex] = useState(props.selected || 0);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.pictureBox}>
                <img src={props.pictures[pictureIndex]} alt="Product picture" />
            </div>
            <div className={classes.thumbs}>
                <div className={Utils.getClasses(classes.thumbBox, pictureIndex === 0 ? classes.selected : "")} onClick={() => setPictureIndex(0)}>
                    <img src={props.pictures[0]} alt="Picture 1" />
                </div>
                <div className={Utils.getClasses(classes.thumbBox, pictureIndex === 1 ? classes.selected : "")} onClick={() => setPictureIndex(1)}>
                    <img src={props.pictures[1]} alt="Picture 2" />
                </div>
            </div>
        </div>
    )
}

export default ProductPictures;