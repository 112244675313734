import React, { useCallback, useEffect, useRef, useState } from "react";
import "./components.css";
import { useSelector, useDispatch } from 'react-redux';
import { useMobileScreen } from "../../custom-hooks/MobileScreen.js";
import { useUndoRedo } from "./ActionPanels/UndoRedoPanel.js";
import { useCopyDelete } from "./ActionPanels/CopyDeletePanel.js";
import { userProfile } from "../Users/UsersActions.js";
import { fetchForegroundColorData, fetchBackgroundColorData, fetchFonts, fetchClipartData, fetchShapeData } from "./EditorAction.js";
import { fetchAllThemes } from "../Books/NewBook/ThemeActions.js";
import { booksActions } from "../Books/BooksSlice.js";
import { publishBook } from "../Order/ProductOrderActions.js";
import { ColorWrapper } from "./ToolPanels/ManageColors.js";
import { Add } from "@material-ui/icons";
import { SignupForm } from "../../components/SignUp/SignUp.js";
import { ForgotPasswordForm } from "../../components/SignUp/ForgotPassword.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import FabricCanvas, { FabricContainer, useFabricCanvas, useFabricCanvasHandler } from "./FabricCanvas.js";
import createBookContext, { BookProvider, PageProvider, useBookContext, useBookContextHandler, usePageContext } from "./BookContext.js";
import Utils from "../../Utils.js";
import LeftController from "./LeftController.js";
import ModalDialog from "../../components/Dialog/Dialog.js";
import classes from "./Editor.module.css";
import RibbonButton from "../../components/Buttons/RibbonButton.js";
import deletePageIcon from '../../assets/delete.svg';
import SecondaryButton from "../../components/Buttons/SecondaryButton.js";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Footer from "./Footer.js";
import closeIcon from '../../assets/close.svg';
import PdfViewer from "../../components/Dialog/PdfViewer.jsx";
import SideBarController from "./SidebarController/SidebarController.js";
import SignInForm from "../../components/SignIn/SignInForm.js";
import Loader from "../../components/Loading/Loader.jsx";
// Button icons
import undoIcon from '../../assets/buttonIcons/icon-undo.svg';
import redoIcon from '../../assets/buttonIcons/icon-redo.svg';
import copyIcon from '../../assets/buttonIcons/icon-duplicate.svg';
import deleteIcon from '../../assets/buttonIcons/icon-delete.svg';
import frontCoverIcon from '../../assets/buttonIcons/icon-front-cover.svg';
import backCoverIcon from '../../assets/buttonIcons/icon-back-cover.svg';
import endPageIcon from '../../assets/buttonIcons/icon-end-page.svg';
import firstPageIcon from '../../assets/buttonIcons/icon-first-page.svg';
import previewIcon from '../../assets/buttonIcons/icon-preview.svg';
import publishIcon from '../../assets/buttonIcons/icon-publish.svg';
import cancelIcon from "../../assets/icon-x.svg";
import { getPaymentConfig } from "../AddCartScreen/AddCartActions.js";
import { ProofreadingPayment } from "../../components/PaymentForms/Payment.js";

const backColor = new ColorWrapper({
    name: 'Textile',
    getColor: function () {
        return window.global?.Designer.AppData.productModelData?.backgroundColor || '';
    },
    locked: true,
    background: true
});

function updateBookDirtyState(book, page, state, saved) {
    if (book.dirty !== state && book.pageMounted && book.canUndoRedo()) {
        book.setDirty(state);
        book.fire('book:dirty-changed', { target: state });
    }
    page?.setDirty(!saved);
}

const minPageCount = 11;
function PagePanel({ setPage }) {
    const page = usePageContext();
    const [render, setRender] = useState(null);
    const [deletePage, setDeletePage] = useState(null);
    const book = useBookContext();
    const [pageDisclaimer, setPageDisclaimer] = useState("")
    const [canvas] = useFabricCanvasHandler(c => {
        function onUpdates(e) {
            page?.updateThumb();
            updateBookDirtyState(book, page, true);
            setRender(r => !r);
        }
        return {
            'object:added': onUpdates,
            'object:modified': onUpdates,
            'object:removed': onUpdates
        }
    }, [page, book]);

    useBookContextHandler({
        'page:unmounted': function ({ target }) {
            target.updatePage(canvas);
            book.pageMounted = false;
        },
        'page:mounted': function ({ target }) {
            target.updateThumb();
            setRender(r => !r);
            book.pageMounted = true;
        },
        'page:removed': function () {
            updateBookDirtyState(book, page, true);
            setRender(r => !r);
        },
        'page:added': e => updateBookDirtyState(book, page, true),
        'book:loaded': e => updateBookDirtyState(book, page, false),
        'book:saved': e => updateBookDirtyState(book, page, false, true)
    }, [canvas, page]);

    const [pages, setPageData] = useState(null);
    function setPages(pagesData) {
        return Promise.all(pagesData.map(async p => {
            if (!p.getThumbUrl()) {
                p.thumbURL = await p.getThumbnail();
            }
            return p;
        })).then(setPageData);
    }

    useEffect(() => {
        if (book) {
            book.appendPage = e => {
                return Promise.all([
                    book.addPage()
                ]).then(ps => {
                    book?.getAllPages().then(async allPages => {
                        await setPages(allPages);
                        setPage(ps[0]);
                        if (allPages.length === 12)
                            setPageDisclaimer(
                                `You've reached page 24! That's amazing! If your story goes beyond 32 pages, there will be a small extra cost.`
                            );
                        if (allPages.length === 16)
                            setPageDisclaimer(
                                `Wow, You're on Page 32! Great job! To write more pages, there’s a tiny extra cost. Ready to pay more? Keep going for a bigger story!`
                            );
                    });
                })
            }
            window.loading(true);
            book.getPages().then(async e => {
                let prePages = [
                    book.getFrontPage(),
                    book.getFrontInnerPage(),
                    book.getBackInnerPage(),
                    book.getBackPage()
                ];
                await Promise.all(prePages.map(async prom => {
                    let p = await prom;
                    if (!p.getThumbUrl()) {
                        p.thumbURL = await p.getThumbnail();
                    }
                    return p;
                }));
                if (e.length >= minPageCount) {
                    await setPages(e);
                    setPage(e[0], true);
                } else {
                    window.loading(true);
                    let pages = [],
                        prevPages = [...e],
                        addOnPageCount = minPageCount - prevPages.length;
                    for (let i = 0; i < addOnPageCount; ++i) {
                        pages.push(book.addPage(i + 1));
                    }
                    await Promise.all(pages);
                    pages = prevPages.concat(await Promise.all(pages));
                    book.setPages(pages);
                    await setPages(pages);
                    setPage(pages[0], true);
                    window.loading(false);
                }
            }).finally(e => window.loading(false));
        }
    }, [book]);

    function onDrop(ev, idx) {
        let name = ev.dataTransfer.getData("name");
        if (!name) {
            return;
        }
        book?.getAllPages().then(ps => {
            let index = ps.findIndex(e => e.getName() === name);
            if (index > -1) {
                ps.forEach((p, idx) => {
                    p._idx = idx;
                })
                ps.splice(idx, 0, ...ps.splice(index, 1));
                let proms = [];
                ps.forEach((p, idx) => {
                    if (p._idx !== idx) {
                        proms.push(p.setPageNumbers((idx + 1) * 2));
                    }
                    delete p._idx;
                })
                Promise.all(proms).then(e => {
                    page.getDesign().then(design =>
                        window.global.Ext.canvasController.loadDesign(design, page)
                    );
                })
                let order = [...ps];
                book.setPages(order.filter(sp => sp.type === 'regularPage'));
                setPages(order);
            }
        });
    }

    function onDragStart(ev, p) {
        ev.dataTransfer.setData("name", p.getName())
    }

    //automatic scrolldown to the selected page 
    const selectedPageRef = useRef(null);
    useEffect(() => {
        const node = selectedPageRef.current;
        if (node) {
            if (node instanceof Element) {
                node.scrollIntoView();
            }
        }
    }, [page]);

    let pageNumber = 1;
    return <div style={{ padding: "0.5rem" }}>
        {
            pages?.map((p, idx) =>
                <div key={idx}
                    ref={p === page ? selectedPageRef : undefined}
                    className={Utils.getClasses(classes.pagethumb, p === page && classes.selected)}
                    draggable={p.isMovable()}
                    onDrop={ev => p.isMovable() && onDrop(ev, idx)}
                    onDragStart={ev => p.isMovable() && onDragStart(ev, p)}
                    onDragOver={ev => p.isMovable() && ev.preventDefault()}
                >
                    {
                        p.isMovable() &&
                        <div className={classes.deletePageIcon} onClick={e => setDeletePage(p)}>
                            <img src={deletePageIcon} />
                        </div>
                    }
                    <div className={classes.pageView} style={{ backgroundImage: `url(${p.getThumbUrl()})` }} onClick={e => setPage(p)} />
                    <p className={classes.pageNumber}>
                        <span>{++pageNumber}</span>
                        {
                            p.getSubpageNames().slice(1).map(e =>
                                <span key={e}>-{++pageNumber}</span>
                            )
                        }
                    </p>
                </div>
            )
        }
        {
            deletePage && <ModalDialog
                title="Confirm"
                content={
                    <span>Are you sure you want delete this page?</span>
                }
                footer={
                    <>
                        <SecondaryButton onClick={() => setDeletePage(null)}>No</SecondaryButton>
                        <PrimaryButton onClick={async e => {
                            if (book) {
                                let userPages = await book.getPages();
                                userPages.forEach((p, idx) => {
                                    p._idx = idx;
                                })
                                book.removePage(deletePage);
                                userPages = await book.getPages();

                                let proms = [];
                                for (let i = userPages.length; i < minPageCount; ++i) {
                                    proms.push(book.addPage());
                                }
                                let ps = await book.getAllPages();
                                ps.forEach((p, idx) => {
                                    if (p._idx === undefined) {
                                        return;
                                    }
                                    if (p._idx !== idx) {
                                        proms.push(p.setPageNumbers((idx + 1) * 2));
                                    }
                                    delete p._idx;
                                })
                                await Promise.all(proms);
                                if (page === deletePage) {
                                    setPage(userPages[0]);
                                } else {
                                    page.getDesign().then(design =>
                                        window.global.Ext.canvasController.loadDesign(design, page)
                                    );
                                }
                                setPages(ps);
                            }
                            setDeletePage(null);
                        }}>Yes</PrimaryButton>
                    </>
                }
            />
        }
        {
            pageDisclaimer && <ModalDialog
                title={"Disclaimer"}
                content={<div>{pageDisclaimer}</div>}
                onClose={() => setPageDisclaimer("")}
            />
        }
        <div className={classes.pageAdder} onClick={book?.appendPage} title="Add new page">
            <Add style={{ color: "#101010" }} />
        </div>
    </div >
}


function Disclaimer({ onClose, onPublish }) {
    const [disclaimerCheck, setDisclaimerCheck] = useState(false);
    return <ModalDialog
        title="Disclaimer"
        content={
            <>
                <div className="scroll-vertical scroll-style" style={{ height: '20rem' }}>
                    <p className={classes.disclaimerPoints}>At Bookalooza, we strongly recommend using your own original content. Copying from other sources or using inappropriate images is not allowed. If these guidelines are not followed, any content that breaks the rules may be removed during printing. The author will be held responsible if any plagiarism or inappropriate images are found. Orange VTech Pvt Ltd (Bookalooza) is not liable for any legal issues that might result.</p>
                    <p className={classes.disclaimerPoints}>Additionally, the content writing, including any errors in spelling, grammar, punctuation, conciseness, and preciseness, will be the sole responsibility of the user and not Orange VTech Pvt Ltd (Bookalooza). If you wish to get expert help in proofreading, editing, and correcting your work, <b>click below</b> to have it reviewed by our experts for an additional fee.</p>
                </div>
                <div className={classes.check}><input type="checkbox" onChange={e => setDisclaimerCheck(!disclaimerCheck)} /> Proofread, Edit and Correct my content
                I agree</div>
            </>
        }
        footer={
            <>
                <SecondaryButton onClick={e => {
                    onClose();
                    setDisclaimerCheck(false)
                }}>Close</SecondaryButton>
                <PrimaryButton disabled={disclaimerCheck ? false : true}
                    onClick={e => onPublish()}
                >OK</PrimaryButton>
            </>
        }
    />
}

function RibbonController({ setPage, footerRef }) {
    const [undo, redo] = useUndoRedo();
    const [copy, remove] = useCopyDelete();
    const [url, setUrl] = useState('');
    const [showPublish, setShowPublish] = useState()
    const [errorMessages, setErrorMessages] = useState(null);
    const [disclaimerMessage, setDisclaimerMessage] = useState(false);
    const bookCtx = useBookContext();
    const userDetails = useSelector(state => state.user.user);
    const [product, setProduct] = useState();
    const [config, setConfig] = useState();

    function previewHandler() {
        footerRef.current.onBookSave().then(e => {
            const pdfPath = `${window.origin}/designer/books/preview/${bookCtx.getId()}?${userDetails.id}${bookCtx.getUpdateDate()}`;
            setUrl(`${window.origin}/flipbook/index.html?file=${encodeURIComponent(pdfPath)}`);
        }).catch(e => { })
    }

    async function publishHandler() {
        try {
            await footerRef.current.onBookSave();
            await Utils.validateBook(bookCtx);
            setDisclaimerMessage(true);
        } catch (error) {
            if (error?.messages) {
                setErrorMessages(error.messages);
            }
        }
    }

    const dispatch = useDispatch();

    useEffect(() => {
        const handleKeyDown = (event) => {
            const modifier = event.ctrlKey || event.metaKey;
            if (modifier && event.key === 'z' && !undo.state?.disabled) {
                undo.onAction();
            }
            if (modifier && event.key === 'y' && !redo.state?.disabled) {
                redo.onAction();
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [undo, redo]);

    useEffect(() => {
        if (Utils.isUserLoggedIn()) {
            getPaymentConfig().then(cnf => {
                setConfig(cnf);
            })
        }
    }, [Utils.isUserLoggedIn()])

    return <div className={classes.ribbon}>
        <div className={Utils.getClasses(classes.ribbonControls, classes.hideRibbonControls)}>
            <RibbonButton icon={undoIcon} disabled={undo.state?.disabled} onClick={undo.onAction} title="Undo (CTRL + z)" />
            <RibbonButton icon={redoIcon} disabled={redo.state?.disabled} onClick={redo.onAction} title="Redo (CTRL + y)" />
            <RibbonButton icon={copyIcon} disabled={copy.state?.disabled} onClick={copy.onAction} title="Duplicate" />
            <RibbonButton icon={deleteIcon} disabled={remove.state?.disabled} onClick={remove.onAction} title="Delete" />
        </div>
        <div className={classes.ribbonControls}>
            <RibbonButton
                className={classes.footerButton}
                onClick={e => bookCtx.getFrontPage().then(e => setPage(e, true))}
                icon={frontCoverIcon}
                title={"Front Cover"}
            />
            <RibbonButton
                className={classes.footerButton}
                onClick={e => bookCtx.getBackPage().then(e => setPage(e, true))}
                icon={backCoverIcon}
                title={"Back Cover"}
            />
        </div>
        <div className={classes.ribbonControls}>
            <RibbonButton
                className={classes.footerButton}
                onClick={e => bookCtx.getFrontInnerPage().then(e => setPage(e, true))}
                icon={firstPageIcon}
                title={"First Page"}
            />
            <RibbonButton
                className={classes.footerButton}
                onClick={e => bookCtx.getBackInnerPage().then(e => setPage(e, true))}
                icon={endPageIcon}
                title={"End Page"}
            />
        </div>
        <div className={classes.ribbonControls}>
            <RibbonButton
                icon={previewIcon}
                title={"Preview"}
                onClick={previewHandler}
            />
            <RibbonButton
                onClick={publishHandler}
                icon={publishIcon}
                title={"Publish"}
            />
        </div>
        <img className={classes.closeIcon} src={closeIcon} onClick={footerRef.current?.onBookClose} style={{ marginRight: "2rem", cursor: 'pointer' }} />
        {
            url && <PdfViewer
                contentClassName={classes.eulaContainer}
                className={classes.modelDialog}
                closeLabel="Back to editing"
                url={url}
                maxWidth='calc(100%-64px)'
                onClose={() => setUrl('')}
                publishHandler={() => {
                    setUrl('')
                    publishHandler();
                }}
            />
        }
        {
            disclaimerMessage && <Disclaimer
                onClose={() => {
                    setDisclaimerMessage(false)
                }}

                onPublish={() => {
                    publishBook(bookCtx).then((e) => {
                        setProduct(e.data.data)
                        setDisclaimerMessage(false)
                        setShowPublish(true)
                    })
                }}
            />
        }
        {
            showPublish && <ProofreadingPayment 
            config={config} 
            book={product} 
            userData={userDetails} 
            setShow={setShowPublish} 
            key={"proofreading-modal"}
            />
        }
        {
            errorMessages && <ModalDialog
                title="Alert"
                content={
                    errorMessages.map(e => {
                        return <div>{e}</div>;
                    })
                }
                footer={
                    <>
                        <SecondaryButton onClick={() => {
                            setErrorMessages(null)
                        }
                        }>Close</SecondaryButton>
                    </>
                }

            />
        }
    </div>;
}

const LoginDialog = ({ onClose, confirm, bookCtx }) => {
    const [formDisplay, setFormDisplay] = useState('login')
    const dispatch = useDispatch();

    function fetchProfile() {
        userProfile(dispatch).then(e => {
            bookCtx?.setAuthor(e?.data?.fullName || "Author");
            confirm?.accept(e);
        });
    }

    function postSignInProcess() {
        localStorage.setItem("isLoggedIn", "true");
        onClose?.();
        fetchProfile();
    }

    function postSignUpProcess() {
        onClose?.();
        setFormDisplay('login');
        userProfile(dispatch);
        fetchProfile();
    }

    function postForgotPassword() {
        onClose?.();
        setFormDisplay('login');
        userProfile(dispatch);
        fetchProfile();
    }

    return <ModalDialog
        content={<>
            <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '1rem' }}>
                <div>Please sign up to continue creating your Book of Dreams.</div>
                <div>We don't want you to lose your hard work!</div>
            </div>
            <div style={{ position: 'relative', height: '34rem' }}>
                {
                    formDisplay === 'login' && <SignInForm
                        postSignInProcess={postSignInProcess}
                        createHandler={e => setFormDisplay('signup')}
                        forgotPasswordHandler={e => setFormDisplay('forgotPassword')}
                        fetchedUserDetails={{}}
                    /> ||
                    formDisplay === 'signup' && <SignupForm
                        postSignUpProcess={postSignUpProcess}
                        goToLogin={e => setFormDisplay('login')}
                    /> ||
                    formDisplay === 'forgotPassword' && <ForgotPasswordForm
                        postForgotPassword={postForgotPassword}
                        goToLogin={e => setFormDisplay('login')}
                    />
                }
            </div>
        </>}
        maxWidth={"100%"}
        onClose={e => { onClose?.(); confirm?.reject(); }}
        contentClassName={classes.dialogContent}
    />
}

function FullScreenButtonsContainer({ onClose, setPage }) {
    const page = usePageContext();
    const book = useBookContext();
    const [pages, setPageData] = useState(null);
    const [loading, setLoading] = useState(false);

    function setPages(pagesData) {
        return Promise.all(pagesData.map(async p => {
            if (!p.getThumbUrl()) {
                p.thumbURL = await p.getThumbnail();
            }
            return p;
        })).then(setPageData);
    }

    useEffect(() => {
        if (book) {
            book?.getAllPages().then(async allPages => {
                await setPages(allPages);
            });
        }
    }, [book]);

    const pageSetter = (idx) => {
        setPage(pages[idx]).then((e) => { if (e) setLoading(false) })
    }

    const handleLeftClick = useCallback(() => {
        if (pages?.length > 0 && setPage) {
            setLoading(true)
            const idx = pages?.findIndex((p) => p === page);
            if (idx > 0) pageSetter(idx - 1);
        }
    }, [pages, setPage, pageSetter, page]);

    const handleRightClick = useCallback(() => {
        if (pages?.length > 0 && setPage) {
            setLoading(true)
            const idx = pages?.findIndex((p) => p === page);
            if (idx < pages.length - 1) pageSetter(idx + 1);
        }
    }, [pages, page, setPage]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowRight") handleRightClick();
            if (event.key === "ArrowLeft") handleLeftClick();
        }

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [handleRightClick, handleLeftClick]);

    const prevLoaderRef = useRef();

    useEffect(() => {
        prevLoaderRef.current = window.loading;
        window.loading = function (loading) {
            setLoading(state => {
                state = state + (loading ? 1 : -1);
                return state > 0 ? state : 0;
            });
        };
        return () => {
            window.loading = prevLoaderRef.current;
        }
    }, []);


    return <>
        <RibbonButton
            className={classes.fullscreenCloseIcon}
            onClick={onClose}
            icon={cancelIcon}
            title={"Exit full screen"}
        />
        {
            loading && <Loader />
        }
        <div className={classes.pageChangeButtons}>
            <div className={Utils.getClasses(classes.fullscreenLeftButton, (pages?.[0] === page || !page?.isRegularPage()) ? classes.disabled : "")} onClick={handleLeftClick}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "4rem", color: "var(--selection-dark)" }} />
            </div>
            <div className={Utils.getClasses(classes.fullscreenRightButton, pages?.[pages?.length - 1] === page ? classes.disabled : "")} onClick={handleRightClick}>
                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "4rem", color: "var(--selection-dark)" }} />
            </div>
        </div>
    </>
}

export default function Editor({ book }) {
    const isMobScreen = useMobileScreen();
    const canvasWrapperRef = useRef(null)
    const [container, setContainer] = useState(null);
    const foregroundLibrary = useSelector(state => state.foregroundColors.libraryItems) || [];
    const backgroundLibrary = useSelector(state => state.backgroundColors.libraryItems) || [];
    const clipartLibrary = useSelector(state => state.cliparts.libraries) || [];
    const shapeLibrary = useSelector(state => state.shapes.libraries) || [];
    const fontLibrary = useSelector(state => state.fonts.libraries) || [];
    const [page, setPage] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false)

    const [confirmLoginDialogue, setConfirmLoginDialogue] = useState(null);

    const [bookCtx, setBookCtx] = useState(null);
    useEffect(() => {
        const localCtx = createBookContext({ ...book });
        window.loading(true);
        localCtx.initialize().then(setBookCtx).finally(e => window.loading(false));
    }, [book]);

    const dispatch = useDispatch();
    const footerRef = useRef(null);
    const canvas = useFabricCanvas();

    async function openPage(p, firstTime) {
        try {
            if (!Utils.isUserLoggedIn() && !firstTime) {
                await new Promise((accept, reject) => {
                    setConfirmLoginDialogue({ accept, reject });
                })
            }
            canvas?.discardActiveObject();
            setPage(p);
            return true;
        } catch (e) {
            return false;
        }
    }
    const imageData = useSelector(state => state.themes.allThemes);

    useEffect(() => {
        if (!foregroundLibrary.length) {
            fetchForegroundColorData(dispatch);
        }
        if (!backgroundLibrary.length) {
            fetchBackgroundColorData(dispatch);
        }
        if (!clipartLibrary.length) {
            fetchClipartData(dispatch);
        }
        if (!shapeLibrary.length) {
            fetchShapeData(dispatch);
        }
        if (!fontLibrary.length) {
            fetchFonts(dispatch);
        }
        if (!imageData.length) {
            fetchAllThemes(dispatch);
        }
        dispatch(booksActions.updateBookTitle(''));
    }, []);

    useEffect(() => {
        if (bookCtx) {
            let theme = bookCtx.getTheme();
            bookCtx.setThemeCategory(imageData.find(c => c.name === theme) || imageData[0]);
        }
    }, [bookCtx, imageData])

    useEffect(() => {
        let objectProto = window.fabric.Object.prototype,
            controlsSetting = objectProto.controlsSetting,
            textboxControls = { ...controlsSetting.defaultTextboxControls },
            controls = { ...controlsSetting.defaultControls };

        controlsSetting.circle.cornerSize = 11;
        controlsSetting.circle.cornerStrokeColor = '#CBCED0';
        controlsSetting.circle.cornerColor = 'white';

        controlsSetting.icon.cornerSize = 24;
        controlsSetting.icon.cornerStrokeColor = '#CBCED0';
        controlsSetting.icon.cornerColor = 'white';
        controlsSetting.icon.cornerPadding = 10;

        if (isMobScreen) {
            delete controls.mb;
            delete controls.mr;
            delete controls.ml;
            delete controls.mt;

            controlsSetting.circle.cornerSize = 8;
            controlsSetting.icon.cornerSize = 14;
            controlsSetting.icon.cornerPadding = 4;

            delete textboxControls.mb;
            delete textboxControls.mr;
            delete textboxControls.ml;
            delete textboxControls.mt;
        }

        objectProto.controls = controls;
        window.fabric.Textbox.prototype.controls = textboxControls;
    }, [isMobScreen])

    function toggleFullScreen() {
        if (!document.fullscreenElement) {
            canvasWrapperRef.current.requestFullscreen();
            setIsFullScreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (document.fullscreenElement === canvasWrapperRef.current) {
                setIsFullScreen(true);
            } else {
                setIsFullScreen(false)
            }
        };
        const handleKeyDown = (event) => {
            if (event.key === "F11") {
                event.preventDefault();
                toggleFullScreen();
            }
        }
        // Add event listener for fullscreen changes
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return <BookProvider value={bookCtx}>
        <PageProvider value={page}>
            <FabricContainer value={container}>
                <div style={{ display: 'flex', flexFlow: 'column', height: 'calc(100% - 3.2rem)', marginTop: '3.2rem' }}>
                    <div style={{ display: 'flex', backgroundColor: 'rgb(237,240,242)', height: '100%', overflowY: 'auto' }}>
                        {
                            isMobScreen ?
                                <SideBarController backColor={backColor} /> :
                                <div className={classes.leftControllerContainer}>
                                    <LeftController className={classes.leftController} backColor={backColor} />
                                </div>
                        }
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <RibbonController setPage={openPage} footerRef={footerRef} />
                            <div className={classes.canvasAndFooter} >
                                <div className={classes.middleContainer}>
                                    <div style={{ flexGrow: 1 }} ref={canvasWrapperRef}>
                                        <FabricCanvas style={{ height: '100%' }}
                                            pageData={page} onShow={setContainer}
                                        />
                                        {
                                            isFullScreen && <FullScreenButtonsContainer onClose={toggleFullScreen} setPage={openPage} />
                                        }
                                    </div>
                                    <Footer ref={footerRef} toggleFullScreen={toggleFullScreen} setConfirmLoginDialogue={setConfirmLoginDialogue} />
                                </div>
                                <div className="dark-background" >
                                    <div className={classes.myPages}>My Pages</div>
                                    <div className={classes.pagePanel}>
                                        <PagePanel setPage={openPage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {confirmLoginDialogue && <LoginDialog
                    bookCtx={bookCtx}
                    onClose={e => setConfirmLoginDialogue(null)}
                    confirm={confirmLoginDialogue}
                />}
            </FabricContainer>
        </PageProvider>
    </BookProvider>;
}