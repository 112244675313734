import { useEffect, useRef, useState } from "react";

function useSpeechToText(options) {
    const [listening, setListening] = useState(false);
    const [transcript, setTranscript] = useState("");
    const [browserSupport, setBrowserSupport] = useState(true);
    const recognitionRef = useRef(null);

    useEffect(() => {
        if (!window.webkitSpeechRecognition) {
            console.error("Web speech api is not supported.");
            setBrowserSupport(false);
            return;
        }

        recognitionRef.current = new window.webkitSpeechRecognition();
        const recognition = recognitionRef.current;
        recognition.interimResults = options.interimResults || true;
        recognition.lang = options.lang || "en-US";
        recognition.continuous = options.continuous || false;

        if (window.webkitSpeechGrammarList) {
            const grammar =
                "#JSGF V1.0; grammar punctuation; public <punc> = . | , | ? | ! | ; | : ;";
            const speechRecognitionList = new window.webkitSpeechGrammarList();
            speechRecognitionList.addFromString(grammar, 1);
            recognition.grammars = speechRecognitionList;
        }

        recognition.onresult = (event) => {
            let text = "";
            const results = event.results;
            for (let i = 0; i < results.length; i++) {
                text += results[i][0].transcript;
            }
            setTranscript(text);
            if (!browserSupport) {
                setBrowserSupport(true);
            }
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error:", event.error);
            if (event.error === "not-allowed") {
                setBrowserSupport(false);
            }
        };

        recognition.onend = () => {
            setListening(false);
            setTranscript("");
        };

        return () => {
            recognition.stop();
        };
    }, []);

    const startListening = () => {
        if (recognitionRef.current && !listening) {
            recognitionRef.current.start();
            setListening(true);
        }
    };

    const stopListening = () => {
        if (recognitionRef.current && listening) {
            recognitionRef.current.stop();
            setListening(false);
        }
    };

    return {
        listening,
        transcript,
        startListening,
        stopListening,
        browserSupport,
    };
}

export default useSpeechToText;
