import React from 'react';
import ReactDOM from 'react-dom';

const container = document.querySelector('head');
function Metatag({name, content, className}) {
    return ReactDOM.createPortal(
        <meta name={name} content={content} className={className}/>, 
        container
    );
}

export default Metatag;