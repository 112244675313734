import React, { useEffect } from "react";
import classes from "./WorkShops.module.css";
import WorkShopForm from "../../components/WorkShopForm/WorkShopForm";
import MainFooter from "../../components/MainFooter/MainFooter";

import studentBannerImage from "../../assets/workShop/student/Student_Banner_Image.jpg";

import imaginationIcon from "../../assets/workShop/student/Schools_Imagination_Icon.png";
import expressionIcon from "../../assets/workShop/student/Schools_Expression_Icon.png";
import confidenceIcon from "../../assets/workShop/student/Schools_Confidence_Icon.png";
import earnIcon from "../../assets/workShop/student/School_Earn_Icon.png";
import certificateIcon from "../../assets/workShop/student/Schools_Certifications_&_ Badges_Icon.png";
import spotLightIcon from "../../assets/workShop/student/Schools_Author Spotlight_Icon.png";

import themeFeatureImage from "../../assets/workShop/student/Theme and Template Selection_Feature Image 1.png";
import contentFeatureImage from  "../../assets/workShop/student/Content_ Creation_Feature Image 2.png";
import reviewFeatureImage from  "../../assets/workShop/student/Review Your Work_Feature Image 3.png";
import publishFeatureImage from  "../../assets/workShop/student/Publish Your Book_Feature Image 4.png";
import printFeatureImage from  "../../assets/workShop/student/Print-on-Demand_Feature Image 5.png";
import royaltyFeatureImage from  "../../assets/workShop/student/Royalty Earnings_Feature Image 6.png";

import moreinfoImage from "../../assets/workShop/student/Single_Image.png";
import Utils from "../../Utils";

const HeroSection = () => {
  return (
    <section
      className={classes.heroSection}
      style={{
        backgroundImage: `url(${studentBannerImage})`,
      }}
    >
      <div className={classes.heroContent}>
        <div className={classes.companyName}>
        </div>
        <div className={classes.heroTitleContainer}>
          <h1>
            <span className={classes.heroTitle}>
              Learn Creative Writing. Your Gateway to Expression.
            </span>
          </h1>
        </div>
        <div className={classes.heroDescritionContainer}>
          <p>
            <span className={classes.heroDescription}>
              Cultivate imaginations, structure narratives, and infuse life into
              your words.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

const RegisterTopSection = () => {
  return (
    <section id="quickQuestion" className={classes.registerTopSection}>
      <div className={classes.registerTopContent}>
        <div className={classes.registerTopLeftPara}>
          <h1 style={{fontSize: '3rem'}}>Quick Question</h1>
          <p>
            Ever find yourself reading your own work, thinking, "Hmm, it's good,
            but it could be better"?
          </p>
          <p>
            You're not alone! Many of us, including experienced writers,
            sometimes feel like our writing could use a dash of sparkle.
          </p>
          <p>
            That's exactly what our creative writing workshop offers. Whether
            you're a newbie or have been scribbling for a while, we believe
            everyone has a storyteller within. Not only will we help you polish
            your skills, but we'll guide you on the path to publishing your
            tales. It's about nurturing creativity, refining the art of
            storytelling, and setting your words free to the world.
          </p>
          </div>

        <div className={classes.registerForm}>
          <h2 className={classes.registerFormTitle}>
            Register for your Creative Writing Workshop Now!
          </h2>
          <WorkShopForm
            workShopName={"student"}
            inputClassName={classes.formInputBox}
          />
        </div>
      </div>
    </section>
  );
};

const BenefitsSection = () => {
  return (
    <section className={classes.benefitsSection}>
      <div className={classes.benefitsContent}>
        <h1 style={{fontSize:"3rem"}}>What’s in it for you?</h1>
        <div className={classes.benefitsList}>
          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={imaginationIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Imagination</h3>
            <p>
              Creative writing lets you be as imaginative as you can, opening
              doors to exciting and unlimited ideas. You can have a superpower
              to bring anything you imagine to life, just with words. Let your
              imagination soar, and let your pen be its wings.
            </p>
          </div>
          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={expressionIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Expression</h3>
            <p>
              It helps you say what's in your heart, making your thoughts and
              emotions come alive in words. No one can tell your story quite
              like you can. So, let your heart speak through the words and
              pictures.
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={confidenceIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Confidence</h3>
            <p>
              When you write and people appreciate it, your confidence blossoms,
              making you believe in your abilities. Over time, you'll stand
              taller, knowing your words have the power to inspire and entertain.
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={earnIcon}
                height="169px"
                width="300px"
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Earn</h3>
            <p>
              With creative writing, not only can you share your stories, but
              you can also sell your books and earn money through royalties.
              Your creativity can turn into a source of income, bringing your
              dreams and ideas to life in more ways than one!
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={certificateIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Certifications and Badges</h3>
            <p>
            Earn certificates of completion and digital badges to showcase your participation and accomplishments in the creative writing workshop,
             enhancing your portfolio and credibility as a writer.
            </p>
          </div>

          <div>
            <div className={classes.benefitsIcon}>
              <img
                src={spotLightIcon}
                alt=" bookalooza benefits"
              />
            </div>
            <h3>Author Spotlight</h3>
            <p>
            Get the chance to be featured in Bookalooza's promotional materials, website, and social media, 
            allowing you to gain additional exposure and connect with a broader audience of readers and fellow writers.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const ClassRoomTalesSection = () => {
  return (
    <section className={classes.classRoomTalesSection}>
      <div className={classes.classRoomContent}>
        <h1 style={{fontSize:'2rem'}}>
          Bookalooza empowers you to unleash your creativity, share your stories
          with the world, and earn from your passion for writing{" "}
        </h1>
        <h4 style={{fontSize:'1.5rem'}}>Bookalooza’s Features</h4>
        <div className={classes.talesListContainer}>
          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={themeFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Theme and Template Selection</h4>
              <p>
                Choose from a variety of themes and templates that suit your
                book's genre and style, giving your book a unique and
                personalized design.
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={contentFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Content Creation</h4>
              <p>
              Write and edit your content, integrate images and graphics seamlessly within the platform, providing you with a user-friendly interface to express your creativity. 
              </p>
            </div>
          </div>
          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={reviewFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Review Your Work</h4>
              <p>
                Preview and review your book before finalizing it, ensuring it
                meets your expectations and is ready to be published.
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={publishFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Publish Your Book</h4>
              <p>
                Publish your book with a simple click, making it available for
                purchase in print format.
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={printFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Print-on-Demand</h4>
              <p>
                Choose to print even 2 hard copies of your book, either in black
                and white or coloured format, and have them delivered to your
                doorstep via courier.
              </p>
            </div>
          </div>

          <div className={classes.talesCard}>
            <div className={classes.talesImageContainer}>
              <img
                src={royaltyFeatureImage}
                alt="class room tales"
              />
            </div>
            <div className={classes.talesContent}>
              <h4>Royalty Earnings</h4>
              <p>
                Earn a 10% royalty for each book sold, with the amount credited
                to your account once it accumulates to Rs 500/-, giving you a
                financial incentive for your literary success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MoreInfoSection = () => {
  return (
    <section className={classes.moreInfoSection}>
      <div className={classes.moreInfoContent}>
        <div className={classes.moreInfoImageContainer}>
          <img src={moreinfoImage} alt="more info" />
        </div>
        <div className={classes.moreInfoText}>
          <h1>Get the Turbo Boost for Creating a Book </h1>
          <p>
            If your storybook is stuck in the slow lane of creation, don’t
            worry, we have got the turbo boost you need. If your book is
            brewing, bubbling, but taking its sweet time to get on paper, our
            Creative Workshop is the express route you’ve been waiting for. Turn
            your story that not only captivates readers but also fills your
            pocket with some well-deserved earnings. Your creativity is the
            engine and Bookalooza has got the tracks ready for you. Hop on,
            write, publish, and earn from your work.
          </p>
        </div>
      </div>
    </section>
  );
};

const RegisterBottomSection = () => {
  return (
    <div className={classes.registerNowSection}>
      <div className={classes.registerNowContent}>
        <h1>Register for your Creative Writing Workshop Now!</h1>
        <div className={classes.registerNowForm}>
          <WorkShopForm
            workShopName={"student"}
            inputClassName={classes.formInputBox}
          />
        </div>
      </div>
    </div>
  );
};

const StudentWorkShops = ({location:{hash}}) => {
  useEffect(() => {
    if (hash) {
      Utils.scrollTo(hash);
    }
  }, [hash])
  return (
    <div>
    <div className={classes.main}>
      <HeroSection />
      <RegisterTopSection />
      <BenefitsSection />
      <ClassRoomTalesSection />
      <MoreInfoSection/>
      <RegisterBottomSection />
    </div>
      <MainFooter/>
    </div>
  );
};

export default StudentWorkShops;
