import React, { useEffect, useState } from "react";
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Editor from './modules/Editor/Editor';
import Home from "./modules/Home/Home";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ProductOrder from "./modules/Order/ProductOrder";
import NewBook from "./modules/Books/NewBook/NewBook";
import { getBook, getBookBySlag } from "./modules/Books/BooksActions";
import AddCartScreen from "./modules/AddCartScreen/AddCartScreen"
import { fetchAllUserImages } from "./modules/Editor/ToolPanels/UploadUserImages/UploadImagesAction";

function SocketConnection(opt) {
    for (let pr in opt) {
        this[pr] = opt[pr];
    }
    if (!this.maxTries) {
        this.maxTries = 5;
    }
}

SocketConnection.prototype = {
    timeInterval: 8000,
    connect: function () {
        delete this.disconnect;
        let loc = window.location;
        let ws = new WebSocket(`${loc.protocol === "http:" ? "ws" : "wss"}://${loc.host}/designer/chat`);
        this.ws = ws;
        ws.onmessage = this.onmessage;
        ws.onclose = () => {
            if (!this.disconnect && this.maxTries-- > 0) {
                setTimeout(this.connect.bind(this), this.timeInterval);
            }
        };
    },
    close: function () {
        this.disconnect = true;
        this.ws?.close();
    }
};

function EditBookView(props) {
    const { match: { url, params }, location:{ pathname } } = props;
    const urlSlag = params.urlSlag;
    const [book, setBook] = useState();
    
    const dispatch = useDispatch();
    useEffect(() => {
        if (urlSlag) {
            getBookBySlag(dispatch, urlSlag).then(setBook);
        }
    }, [urlSlag]);
    
    return <>
        {
            book && <div style={{display: url === pathname ? 'contents' : 'none' }}>
                <Editor book={book} />
            </div>
        }
        <Route exact path={`${url}/order`} component={ProductOrder} />
    </>;
}

export default function Dashboard({ match: { url }, location: {pathname} }) {
    if (url === "/") {
        url="";
    }

    const dispatch = useDispatch()
    useEffect(() => {
        fetchAllUserImages(dispatch);
    }, [])

    return <Switch>
        <Route path={`${url}/user`} component={Home} />
        <Route path={`${url}/edit/:urlSlag`} component={EditBookView} />
        <Route exact path={`${url}/change-password`} component={ChangePassword} />
        <Route exact path={`${url}/cart`} component={AddCartScreen}/>
    </Switch>;
}