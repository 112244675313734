import { createSlice } from "@reduxjs/toolkit";
import Utils from "../../Utils";

const initialState = {
    cartItems: [],
    orderSummary: {
        shippingAmount: 45,
        shippingLimit: 1000
    },
    couponDetails: {
        promoCode: "",
        couponDiscAmount: 0
    }
}

export function getUserCart() {
    const user = Utils.getCurrentUser()
    return JSON.parse(localStorage.getItem(`cart_${user.id}`) || "[]");
}

function saveCartItems(value) {
    const user = Utils.getCurrentUser()
    localStorage.setItem(`cart_${user.id}`, JSON.stringify(value))
}

export function clearCart() {
    const user = Utils.getCurrentUser()
    localStorage.removeItem(`cart_${user.id}`)
    localStorage.removeItem(`couponCode_${user.id}`)
}

export function saveAppliedCouponCode(value) {
    const user = Utils.getCurrentUser()
    localStorage.setItem(`couponCode_${user.id}`, value)
}

export function removeAppliedCouponCode() {
    const user = Utils.getCurrentUser()
    localStorage.removeItem(`couponCode_${user.id}`)
}

export function getAppliedCouponCode() {
    const user = Utils.getCurrentUser();
    return JSON.parse(localStorage.getItem(`couponCode_${user.id}`)) || {};
}

function saveShippingCharge(value) {
    const user = Utils.getCurrentUser()
    localStorage.setItem(`shippingCharge_${user.id}`, value)
}

export function getShippingChargeByCart() {
    const user = Utils.getCurrentUser();
    const shippingAmount = localStorage.getItem(`shippingCharge_${user.id}`) || "";
    return parseInt(shippingAmount);
}

const addCartSlice = createSlice({
    name: "addCartSlice",
    initialState: initialState,
    reducers: {
        fetchCartItems: (state, action) => {
            state.cartItems = getUserCart();
        },
        removeAllCartItems: (state, action) => {
            state.cartItems = [];
            saveCartItems([]);
        },
        addNewCartItem: (state, action) => {
            const index = state.cartItems.findIndex(x => action.payload.id === x.id);
            if (index > -1) {
                state.cartItems[index].count += 1;
                state.cartItems.splice(index, 1);
            }
            else {
                const user = Utils.getCurrentUser();
                const item = action.payload;
                if (user.id === item.authorId) {
                    item.count = 2;
                }
                state.cartItems.push(item);
            }
            saveCartItems(state.cartItems);
        },
        removeCartItem: (state, action) => {
            const index = state.cartItems.findIndex(x => action.payload.id === x.id);
            if (index > -1) {
                state.cartItems.splice(index, 1);
            }
            saveCartItems(state.cartItems);

        },
        removeCouponDiscount: (state, action) => {
            state.cartItems.forEach((item) => {
                item.couponDisc = 0;
            });
            state.couponDetails = { promoCode: "", couponDiscAmount: 0 };
            saveCartItems(state.cartItems);
            removeAppliedCouponCode();
        },
        updateCartItem: (state, action) => {
            const index = state.cartItems.findIndex(x => action.payload.id === x.id);
            if (index > -1) {
                state.cartItems[index] = { ...action.payload };
            }
            saveCartItems(state.cartItems);
        },
        updateCartItemPriceSummary: (state, action) => {
            const index = state.cartItems.findIndex(x => action.payload.id === x.id);
            if (index > -1) {
                state.cartItems[index]['quantityDiscount'] = action.payload.quantityDiscount;
                state.cartItems[index]['authorDiscount'] = action.payload.authorDiscount;
                state.cartItems[index]['totalAmount'] = action.payload.totalAmount;
            }
            saveCartItems(state.cartItems)
        },
        getCouponDetails: (state, action) => {
            state.couponDetails = getAppliedCouponCode();
        },
        updateCouponCodeDiscount: (state, action) => {
            state.couponDetails.promoCode = action.payload.couponCode;
            state.couponDetails.couponDiscAmount = action.payload.totalDiscount;
            saveAppliedCouponCode(JSON.stringify(state.couponDetails));
        },
        updateCartShippingCharge: (state, action) => {
            const shippingCharge = action.payload.shippingCharge;
            state.shippingCharge = shippingCharge;
            saveShippingCharge(shippingCharge);
        }
    },
});

export const getSubTotalAmount = (state) => {
    let subTotal = 0;
    state.addCart.cartItems.map((cartItem, i) => {

        subTotal = subTotal + (cartItem.totalAmount);
    })
    return subTotal;
}

export const getShippingAmount = (state) => {
    let shippingAmount = 0;
    state.addCart.cartItems.map((cartItem, i) => {
        shippingAmount = shippingAmount + (cartItem.shippingCharge);
    })
    return shippingAmount;
}

export const getCouponCodeDiscAmount = (state) => {
    let couponDiscAmount = 0;
    state.addCart.cartItems.map((cartItem) => {
        if (cartItem?.couponDisc) {
            couponDiscAmount += cartItem?.couponDisc;
        }
    })
    return couponDiscAmount;
}

export const getpromoCode = (state) => {
    const promoCode = state.addCart.couponDetails.promoCode;
    return promoCode;
}

export const addCartActions = addCartSlice.actions;
export default addCartSlice.reducer;

// Action creators are generated for each case reducer function
export const { addNewCartItem, removeAllCartItems, removeCartItem, updateCartItem, fetchCartItems, updateCartItemPriceSummary, updateCartShippingCharge, getCouponDetails } = addCartSlice.actions;