import AIData from "../../../../components/AIData/AIData";
import classes from "./Storyline.module.css";

export default function Scenes({values}) {
  return <div className={classes.container}>
      <div className={classes.plotsMain} >
        {values?.map((q) => (<AIData text={q} />))}
      </div>
  </div>;
}
