import BaseSelect from 'react-select';

export default function Select({clearable, onChange, value, isOptionDisabled, options, styles}) {
    return <div style={{padding: 3}}>
        <BaseSelect
            options={options}
            isOptionDisabled={isOptionDisabled}
            value={value}
            onChange={onChange}
            clearable={clearable}
            styles={{
                control: (style, state) => {
                    let color = state.isFocused && 'var(--selection-dark)';
                    return {
                        ...style,
                        borderRadius: "7px",
                        backgroundColor: "var(--input-background-color)",
                        borderColor: color,
                        boxShadow: "0 0 0 1px " + color,
                        '&:hover': { 
                            borderColor: color
                        },
                        ...styles?.control?.(style, state)
                    }
                },
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? 'var(--selection-dark)' : 'inherit',
                    '&:hover': { backgroundColor: state.isSelected ? 'var(--selection-dark)' : 'var(--selection-light)' },
                    ...styles?.option?.(provided, state)
                })
            }}
        />
    </div>
}