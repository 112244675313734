import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFullTheme } from "../../../Books/NewBook/ThemeActions";
import ImageWidget from "../ImageView";
import classes from "./BackCovers.module.css";
import Container from "../components/Container";
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { useFabricCanvas, useFabricCanvasHandler } from "../../FabricCanvas";
import { getAuthorImageTemplate } from "../../BookContext";

function BackCovers({ visible, taggablePanel, setOpen }) {
    const canvas = useFabricCanvas();
    const imageData = useSelector((state) => state.themes.backPage);
    const [isAuthorPicture, setIsAuthorPicture] = useState(false);

    useEffect(() => {
        if (canvas) {
            const fabricObjects = canvas._objects;
            setIsAuthorPicture(fabricObjects.some((i) => i.id === "authorPhoto"));
        }
    }, [canvas])
    useFabricCanvasHandler(canvas => {
        return {
            'canvas:x-ready': function (e) {
                if (canvas) {
                    const fabricObjects = canvas._objects;
                    setIsAuthorPicture(fabricObjects.some((i) => i.id === "authorPhoto"));
                }
            }
        }
    });

    return (
        <Container visible={visible}>
            <ImageWidget
                visible={true}
                imageData={imageData}
                title="Back Cover"
                hasCategory={false}
                showAllItemes
                taggablePanel={taggablePanel}
                setOpen={setOpen}
                getCategory={(categories, idx) => getFullTheme(categories[idx])}
            />
            <div className={classes.backCoverAuthorImg}>
                <span>Add your picture:</span>
                <CheckBox
                    value={isAuthorPicture}
                    onChange={async (e) => {
                        const isChecked = e.target.checked;
                        if (isChecked) {
                            const backJson = canvas.toJSON();
                            if (!backJson.objects.some(i => i.id === "authorPhoto")) {
                                const authorImageTemplate = await getAuthorImageTemplate();
                                backJson.objects.push(...authorImageTemplate.objects);
                                canvas.loadFromJSON(backJson);
                            }
                            setIsAuthorPicture(true)
                        } else {
                            const fabricObjects = canvas?._objects;
                            const objects = fabricObjects?.filter((i) => i.id !== "authorPhoto");
                            fabricObjects.splice(0, fabricObjects.length, ...objects);
                            canvas.discardActiveObject();
                            canvas.renderAll();
                            setIsAuthorPicture(false);
                        }
                    }}
                />
            </div>
        </Container>
    );
}

export default BackCovers;
