import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import MainFooter from "../../../components/MainFooter/MainFooter";
import bannerImage from "../../../assets/images/store-banner.svg";
import classes from "./BookStore.module.css";
import Select from "../../../components/Select";
import {
  getBestSellingBooks,
  getCustomStoreSections,
  getFilteredBooksCount,
  getMostPopularBooks,
  getStoreBooks,
} from "../BooksActions";
import BookItem from "../../../components/BookItem/BookItem";
import Utils from "../../../Utils";
import ReactPaginate from "react-paginate";
import previousLabelIcon from "../../../assets/images/pagination-previous-icon.svg";
import nextLabelIcon from "../../../assets/images/pagination-next-icon.svg";
import SearchBar from "../../../components/SearchBar/SearchBar";
import StoreSection from "./StoreSection";

function Input({ onChange, value, min, max }) {
  const [currentValue, setCurrentValue] = useState(value || min);
  useEffect(() => {
    setCurrentValue(Number(value));
  }, [value]);
  return (
    <input
      type="number"
      onBlur={(e) => {
        let value = currentValue;
        if (value < min) {
          value = min;
        } else if (value > max) {
          value = max;
        }
        onChange?.(value);
        setCurrentValue(value);
      }}
      onChange={(e) => {
        setCurrentValue(Number(e.target.value));
      }}
      style={{
        width: "1.5rem",
        fontSize: "1rem",
        outline: "none",
        border: "none",
        fontFamily: "Poppins",
      }}
      value={currentValue}
    />
  );
}

const SortBy = {
  author: "author",
  title: "title",
  publishTime: "publishDate",
  rating: "overallRating",
};

const sortOptions = [
  {
    value: SortBy.author,
    label: "Author's Name",
  },
  {
    value: SortBy.title,
    label: "Book Title",
  },
  {
    value: SortBy.publishTime,
    label: "Newest First",
  },
  {
    value: SortBy.rating,
    label: "Book Rating",
  },
];

const pageSize = 25;

function BookStore() {
  const [searchKey, setSearchKey] = useState("");
  const [sortBy, setSortBy] = useState(SortBy.publishTime);
  const books = useSelector((state) => state.books.storeBooks);
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedBook, setSelectedBook] = useState(null);
  const customStore = useSelector((state) => state.books.customStoreSectionBooks);
  const bestSellingBooks = useSelector((state) => state.books.bestSellingBooks);
  const [customSectionBooks, setCustomSectionBooks] = useState({});
  const [customSectionHeadings, setCustomSectionHeadings] = useState([]);

  useEffect(() => {
    getMostPopularBooks(dispatch);
    getBestSellingBooks(dispatch);
    getCustomStoreSections(dispatch)
  }, []);

  useEffect(() => {
    if (!selectedBook && bestSellingBooks?.length) {
      setSelectedBook(bestSellingBooks[0]);
    }
  }, [bestSellingBooks]);

  function sortByConditions(sortBy) {
    if (sortBy === "author" || sortBy === "title") {
      return `${sortBy} ASC`;
    }
    return `${sortBy} DESC`;
  }

  useEffect(() => {
    const regex = `.*${searchKey.split(" ").join("\\s*")}.*`;
    let filter = {
      where: {
        $or: [
          {
            author: {
              $regex: regex,
              $options: "i",
            },
          },
          {
            title: {
              $regex: regex,
              $options: "i",
            },
          },
        ],
        status: "PUBLISHED",
      },
      sortBy: sortByConditions(sortBy),
      offset: (pageNumber - 1) * pageSize,
      limit: pageSize,
    };
    getStoreBooks(dispatch, filter);
    getFilteredBooksCount(filter.where).then(function (response) {
      let data = response.data;
      setTotalPages(Math.ceil(data.data.count / pageSize));
    });
  }, [searchKey, sortBy, pageNumber]);

  function handlePageChange(e) {
    setPageNumber(e.nextSelectedPage + 1);
  }

  useEffect(() => {
    if (customStore.headings) {
      let headingSection = [...customStore?.headings];
      const books = customStore?.books;

      const groupedBooks = {}
      books?.forEach(book => {
        if (book?.customStoreSection) {
          book.customStoreSection.forEach(id => {
            if (groupedBooks[id]) {
              groupedBooks[id].push(book)
            } else {
              groupedBooks[id] = [book]
            }
          });
        }
      });

      setCustomSectionHeadings(headingSection);
      setCustomSectionBooks(groupedBooks)
    }
  }, [customStore])

  let renderIndex = 0;


  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.paddedContainer}>
          <div className={classes.imageWrapper}>
            <img
              src={bannerImage}
              className={classes.banner}
              alt="Store banner"
            />
            <h1 className={classes.bannerHeading}>Bookstore</h1>
          </div>
          {Boolean(customSectionHeadings.length) &&
            customSectionHeadings.map((heading, idx) => {
              if (customSectionBooks[heading.id]?.length > 0) {
                const theme = renderIndex % 2 === 0 ? "purple" : "orange";
                renderIndex++;
                return (
                  <StoreSection
                    heading={heading.heading}
                    theme={theme}
                    key={heading.id}
                    books={customSectionBooks[heading.id]}
                  />
                );
              }
            })}
          <div className={classes.innerContainer}>
            <div className={classes.search}>
              <SearchBar
                placeholder={"Search By Title, Author"}
                className={classes.searchBoxWrapper}
                onSearch={setSearchKey}
              />
              <Select
                key={"Select"}
                closeMenuOnSelect={true}
                options={sortOptions}
                onChange={(e) => {
                  const selected = sortOptions.find(
                    (opt) => e.value === opt.value
                  );
                  setSortBy(selected?.value);
                }}
                value={sortOptions.find((i) => i.value === sortBy)}
                placeholder="Sort By..."
                clearable={true}
                styles={{
                  control: (e) => ({
                    fontFamily: "Poppins",
                    width: "12rem",
                    cursor: "pointer",
                    background: "white",
                    border: "1px solid black",
                  }),
                  option: (base, state) => {
                    return { fontFamily: "Poppins" };
                  },
                }}
              />
            </div>
            <div className={classes.bookList}>
              {books.map((book) => {
                return (
                  <BookItem
                    key={book.id}
                    book={book}
                    style={{ padding: "0.8rem" }}
                    onClick={() => {
                      Utils.showBookDetails(history, book.urlSlag);
                    }}
                  />
                );
              })}
            </div>
            <ReactPaginate
              key={"Pagination"}
              nextLabel={
                <>
                  <img
                    className={
                      pageNumber === totalPages + 1
                        ? classes.disabledButton
                        : ""
                    }
                    src={nextLabelIcon}
                    alt="next"
                  />
                </>
              }
              onClick={handlePageChange}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={totalPages}
              previousLabel={
                <>
                  <img
                    className={pageNumber === 2 ? classes.disabledButton : ""}
                    src={previousLabelIcon}
                    alt="next"
                  />
                </>
              }
              pageClassName={classes.pageItem}
              pageLinkClassName={classes.pageLink}
              disableInitialCallback
              previousClassName={classes.pageItemPrevious}
              previousLinkClassName={classes.pageLink}
              nextClassName={classes.pageItem}
              nextLinkClassName={classes.pageLink}
              breakLabel="..."
              breakClassName={classes.break}
              breakLinkClassName="page-link"
              containerClassName={classes.pagination}
              activeClassName={classes.pageItemActive}
            />
          </div>
        </div>
      </div>
      <MainFooter key={"Footer"} />
    </>
  );
}

export default BookStore;
