import React from 'react';
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import Card from '../Card/Card';
import classes from "./ReviewItem.module.css";
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import Utils from '../../Utils';

function ReviewItem({ item, onClick}) {
  const currUser = Utils.getCurrentUser();
  return (
    <Card backColor="#ffe7d6" padding="1.5rem" hasShadow={false}>
        <div className={classes.mainContainer}>
            <div className={classes.pictureBox}>
                <ProfilePicture pictureSource={item.photoUrl} size='3.2rem'/>
            </div>
            <div className={classes.detailsBox}>
                <h3 className={classes.reviewerName}>
                    {item.userId}
                </h3>
                <StarRatings
                    rating={item.rating || 0}
                    starRatedColor="#FBB805"
                    numberOfStars={5}
                    starDimension="1.125rem"
                    starSpacing="0.125rem"
                />
                <p className={classes.reviewText}>
                    {item.comment}
                </p>
                <p className={classes.timeBox}>{Utils.timeSince(item.time)}</p>
            </div>
        </div>
       {currUser.role === 'admin' && ( 
           <FontAwesomeIcon onClick={() => onClick(item.id)} 
             style={{cursor: "pointer"}} fontSize={20} icon={faTrash} 
    />)}
    </Card>
  )
}

//"profileimage": "https://i.ibb.co/3SMLrjs/bitcoin.png",
//"rating": 4,
//"time": 1212454

export default ReviewItem;