import { useEffect, useState } from "react";
import Select from "../../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import { fetchCharacters, fetchCharacter } from "./AIHelpAction";
import { useBookContext } from "../BookContext";
import classes from "./AIHelp.module.css";
import { useFabricCanvas } from "../FabricCanvas";
import AIData from "../../../components/AIData/AIData";

export default function Character() {
  const characters = useSelector((s) => s.aiHelp.characters);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();
  const [selectedAttr, setSelectedAttr] = useState();

  const canvas = useFabricCanvas();
  const bookCtx = useBookContext();
  useEffect(() => {
    if (!characters?.length) {
      fetchCharacters(dispatch);
    } else {
      const character =
        characters.find((q) => q.theme === bookCtx.getTheme()) || characters[0];
      fetchCharacter(character.id).then(setSelected);
    }
  }, [characters]);

  //const themeOptions = characters?.map((q) => ({ label: q.theme, value: q.id })) || [];
  const attrOptions = selected?.values.map(attr => ({label: attr.attribute, value: attr.values}));
  return (
    <div className={classes.container}>
      {/* <Select
        options={themeOptions}
        value={themeOptions.find((t) => t.value === selected?.id)}
        onChange={(e) => fetchCharacter(e.value).then(setSelected)}
      /> */}
      <Select
        options={attrOptions}
        onChange={(e) => setSelectedAttr(e.value)}
      />
      <div style={{margin: '0.5rem'}} />
      <div className={classes.innerMain}>
        {
          selectedAttr &&
          selectedAttr
          .replaceAll(/,[0-9]+\./g, text => '|,|' + text.substring(1))
          .split('|,|')
          .map((i, idx) => <AIData key={idx} text={i} addIcon={false}/>)
        }
      </div>
    </div>
  );
}
