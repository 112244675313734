import React from 'react';
import Utils from '../../Utils';
import Lottie from "react-lottie-player";
import classes from "./ThemeItem.module.css";
import aiAnimation from "../../assets/aiAnimation.json";

function ThemeItem(props) {
    return (
        <div
            className={Utils.getClasses(classes.themeItem, props.selected ? classes.selected : "")}
            onDoubleClick={() => props?.onDoubleClick?.()}
            onClick={() => props.onClick?.()}
            style={ props.selected ? {} : {...props.style}}
        >
            <img className={classes.themeImage} src={props.themeImage} alt=""/>
            <div className={classes.themeName}>{props.themeName}</div>
            {props.ai &&
                <div className={classes.aiLogo}>
                    <span>AI</span>
                    {/* <div>
                        <Lottie
                            loop
                            animationData={aiAnimation}
                            play
                            style={{ width: "100%", height: "80%" }}
                        />
                    </div> */}
                </div>
            }
        </div>
    );
}

export default ThemeItem;