import React, { useEffect, useState } from "react";
import classes from "./SpeechToText.module.css";
import Container from "../components/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import TextArea from "../../../../components/TextArea/TextArea";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { useFabricCanvas } from "../../FabricCanvas";
import { addTextboxObject } from "../../objectUtils";
import useSpeechToText from "../../../../custom-hooks/UseSpeechToText";

function SpeechToText({ visible }) {
  const { transcript, browserSupport, listening, startListening, stopListening } = useSpeechToText({ continuous: true })
  const canvas = useFabricCanvas();
  const [text, setText] = useState("");

  useEffect(() => {
    if (transcript) {
      setText(transcript);
    }
  }, [transcript]);

  return (
    <Container visible={visible} title={"Speech to text"}>
      <div className={classes.main}>
        <div className={classes.container}>
          <div className={classes.formWrapper}>
            <div className={classes.textInputWrapper}>
              <TextArea
                value={text}
                className={classes.input}
                onChange={(e) => setText(e.target.value)}
                placeHolder="Please click the button and start speaking."
              />
            </div>
            <div className={classes.buttonsWrapper}>
              <SecondaryButton
                onClick={
                  listening ? stopListening : startListening
                }
                className={listening ? classes.recordingButton : ""}
              >
                <FontAwesomeIcon
                  icon={listening ? faMicrophoneSlash : faMicrophone}
                />
              </SecondaryButton>
              <PrimaryButton
                onClick={() => addTextboxObject(canvas, { text: text })}
              >
                Add
              </PrimaryButton>
            </div>
            {!browserSupport && (
              <span className={classes.error}>
                Your browser does not support speech recognition. Please allow
                access to your microphone.
              </span>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default SpeechToText;
