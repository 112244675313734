import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalDialog from "../Dialog/Dialog";
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import { addNewCartItem, getAppliedCouponCode, removeAllCartItems } from '../../modules/AddCartScreen/AddCartSlice';
import CheckoutForm from "../PaymentForms/CheckoutForm";
import { createOrder, createProofReadingOrder, schoolRecharge, updateOrderStatus, updateProofreadingStatus, updateRechargeStatus } from '../PaymentForms/PaymentAction';
import logoIcon from "../../assets/bookalooza-logo.png";
import useRazorpay from 'react-razorpay';
import InfoForm from './InfoForm';
import { fetchProfile } from '../../modules/Users/UsersActions';
import { createContext } from 'react';
import CheckBoxPrimary from '../CheckBox/CheckBoxPrimary';
import classes from "./Payment.module.css"
import PrimaryButton from '../Buttons/PrimaryButton';
import Consts from '../../Consts';
import { useHistory } from "react-router";
import Modal, { ModalFooter } from '../Modal/Modal';

const PaymentContext = createContext();
const PaymentInnerContainer = PaymentContext.Provider;

export function PaymentContainer({ children }) {
    const [Razorpay, isLoaded] = useRazorpay();
    const [razor, setRazor] = useState({});
    useEffect(() => {
        if (isLoaded) {
            setRazor({ value: Razorpay });
        }
    }, [Razorpay, isLoaded]);
    return <PaymentInnerContainer value={razor}>
        {children}
    </PaymentInnerContainer>
}

function useRazor() {
    return useContext(PaymentContext).value;
}

function usePayment(config, userData, updateOrderStatus, onClose, bookId) {
    const [method, setMethod] = useState({
        fn: () => { console.log("Payment Gateway not init") }
    });
    const dispatch = useDispatch();
    const Razorpay = useRazor();

    function setFunction(fn) {
        setMethod({ fn });
    }

    useEffect(() => {
        if (config?.gateway === 'razorpay') {
            setFunction(function (order, setOrderStatus) {
                return new Promise((accept, reject) => {
                    if (!Razorpay) {
                        reject("Razor not found");
                        return;
                    }
                    const options = {
                        key: config.key,
                        order_id: order.gatewayOrderId,
                        description: "Book purchase",
                        image: logoIcon,
                        handler: async function (response) {
                            const resp1 = await updateOrderStatus({
                                ...response,
                                id: order.id,
                                gateway_order_id: response.razorpay_order_id,
                                gateway_payment_id: response.razorpay_payment_id,
                                gateway_payment_status: 'success',
                            }, bookId);
                            if (resp1.status) {
                                setOrderStatus(1);
                                dispatch(removeAllCartItems());
                                fetchProfile(dispatch);
                            }
                            accept(resp1);
                        },
                        modal: {
                            ondismiss: function () {
                                updateOrderStatus({
                                    id: order.id,
                                    razorpay_order_id: order.gatewayOrderId,
                                    gateway_order_id: order.gatewayOrderId,
                                    gateway_payment_id: "",
                                    razorpay_payment_id: "",
                                    razorpay_signature: "",
                                    gateway_payment_status: 'failed',
                                }, bookId).then(accept);
                                onClose?.();
                            }
                        },
                        prefill: {
                            name: userData.fullName,
                            email: userData.userId,
                        },
                        notes: {},
                    };

                    const rzp1 = new Razorpay(options);
                    rzp1.on("payment.failed", async function (response) {
                        const resp1 = await updateOrderStatus({
                            gateway_order_id: order.razorpayOrderId,
                            gateway_payment_id: response.error.metadata.payment_id,
                            razorpay_signature: "",
                            gateway_payment_status: 'failed',
                        }, bookId);
                        setOrderStatus(2);
                        accept(resp1);
                    });
                    rzp1.open();
                })
            });
        } else if (config?.gateway === 'instamojo') {
            setFunction(function (order) {
                if (order.gatewayPaymentLink) {
                    window.location.href = order.gatewayPaymentLink;
                }
            });
        }
    }, [config, userData, Razorpay])
    return method.fn;
}


export default function Payment({ cartItems, setShowCheckoutForm, userData, config }) {
    const [orderStatus, setOrderStatus] = useState(0);
    const dispatch = useDispatch();
    const doPayment = usePayment(config, userData, updateOrderStatus);

    async function handlePayment(state, customerName, customerPhone, city, address, pincode, addressLine2, landmark, alternatePhone) {
        const orderData = cartItems.map(item => {
            const product = {
                bookId: item.id,
                quantity: item.count,
                isProofReadingService: item.isProofReadingService
            };
            return product;
        });

        setShowCheckoutForm(false);
        const couponCode = getAppliedCouponCode().promoCode;
        const params = { "products": orderData, state, customerName, customerPhone, address, pincode, city, addressLine2, landmark, alternatePhone, couponCode };
        const order = await createOrder(params);

        if (order.status === 'success') {
            setOrderStatus(1);
            dispatch(removeAllCartItems());
        } else {
            await doPayment(order, setOrderStatus);
        }
        if (order.usedCredits) {
            fetchProfile(dispatch);
        }
    }
    const Form = userData.isSchoolShipped ? InfoForm : CheckoutForm;
    return <>
        <ModalDialog content={
            <Form setShowCheckoutForm={setShowCheckoutForm} handlePayment={handlePayment} userData={userData} />
        } />
        {orderStatus === 1 &&
            <ModalDialog title="Order Success" content={
                <div>Your order has been placed successfully.</div>
            } footer={
                <div>
                    <SecondaryButton onClick={() => setOrderStatus(0)}>Ok</SecondaryButton>
                </div>
            } />
        }
        {orderStatus === 2 &&
            <ModalDialog title="Payment Failed" content={
                <div>Your order has been failed or declined.</div>
            } footer={
                <div>
                    <SecondaryButton onClick={() => setOrderStatus(0)}>Ok</SecondaryButton>
                </div>
            } />
        }
    </>
}

export function AddWalletBalance({ config, userData, onClose }) {
    const doPayment = usePayment(config, userData, updateRechargeStatus, onClose);
    const [orderStatus, setOrderStatus] = useState(0);


    useEffect(() => {
        if (config && userData && userData.gatewayPaymentStatus !== "success") {
            schoolRecharge().then(e => {
                doPayment(e, setOrderStatus);
            })
        }
    }, [config, userData, doPayment])

    const handleOk = () => {
        setOrderStatus(0);
        onClose?.();
    }

    return <>
        {orderStatus === 1 &&
            <ModalDialog title="Success" content={
                <div>Good news! Your amount of Rs.{userData.credits} is loaded in the wallet. And hey, when it's time to publish, this amount will be adjusted at checkout. So, no need to fret! Create your Book Now!</div>
            } footer={
                <div>
                    <SecondaryButton onClick={handleOk}>Ok</SecondaryButton>
                </div>
            } />
        }
        {orderStatus === 2 &&
            <ModalDialog title="Payment Failed" content={
                <div>Your order has been failed or declined.</div>
            } footer={
                <div>
                    <SecondaryButton onClick={handleOk}>Ok</SecondaryButton>
                </div>
            } />
        }
    </>
}

export function ProofreadingPayment({ config, setShow, book, userData, closePayment }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const doPayment = usePayment(config, userData, updateProofreadingStatus, () => { }, book?.id);
    const [orderStatus, setOrderStatus] = useState(0);
    const [showModal, setShowModal] = useState(true);
    const handleBuyNow = async () => {
        // if (book.id && config && userData) {
        //     createProofReadingOrder(book.id).then(e => {
        //         setShowModal(false)
        //         doPayment(e, setOrderStatus);
        //     })
        // }

        dispatch(addNewCartItem({ ...book, count: 1, isProofReadingService: true }))
        setShowModal(false);
        history.push(`${Consts.BASE_URL}/cart`);
    };

    const pages = book?.totalPages - 4 || 25;

    const handleClose = async () => {
        await setOrderStatus(0);
        const body = document.getElementsByTagName("body")[0];
        if (body.style.cssText === "overflow: hidden;") {
            body.style.cssText = ""
        }

        if (body.hasAttribute("style")) {
            body.removeAttribute("style")
        }
        history.push(`${Consts.BASE_URL}/user/library`);
    }

    return (
        <>
            {
                showModal && <ModalDialog
                    title="Let our Experts handle it!"
                    content={
                        <>
                            <span>
                                Add a professional touch to your writing with our <b>grammar</b> and{" "}
                                <b>proofreading</b> services.
                            </span>
                            <br />
                            <div className={classes.proofreadingContainer}>
                                <p>Which editing and proofreading option would you like?</p>
                                <div className={classes.proofreadingGrid}>
                                    <div className={classes.subGrid}>
                                        <div>24 - 64 pages</div>
                                        <div>Rs. 100</div>
                                        <div>
                                            <CheckBoxPrimary defaultChecked={pages <= 64} disabled={true} />
                                        </div>
                                    </div>
                                    <div className={classes.subGrid}>
                                        <div>64 - 120 pages</div>
                                        <div>Rs. 200</div>
                                        <div>
                                            <CheckBoxPrimary defaultChecked={pages > 64 && pages <= 120} disabled={true} />
                                        </div>
                                    </div>
                                    <div className={classes.subGrid}>
                                        <div>120 and above</div>
                                        <div>Rs. 350</div>
                                        <div>
                                            <CheckBoxPrimary defaultChecked={pages > 120} disabled={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    footer={
                        <>
                            <div className={classes.footer}>
                                <PrimaryButton onClick={handleBuyNow}>Buy Now</PrimaryButton>
                            </div>
                            <div style={{ alignSelf: "flex-end" }}>
                                <SecondaryButton className={classes.skipBtn} onClick={() => {
                                    setShow(false);
                                    history.push(`${Consts.BASE_URL}/user/library`);
                                }}>Skip & continue</SecondaryButton>
                            </div>
                        </>
                    }
                />
            }
            {orderStatus === 1 &&
                <Modal title="Order Success">
                    <div>
                        <div className={classes.modalBody}>Your order has been placed successfully.</div>
                        <ModalFooter>
                            <SecondaryButton onClick={handleClose}>Ok</SecondaryButton>
                        </ModalFooter>
                    </div>
                </Modal>
            }
            {orderStatus === 2 &&
                <Modal title="Payment Failed">
                    <div>
                        <div className={classes.modalBody}>Your order has been failed or declined.</div>
                        <ModalFooter>
                            <SecondaryButton onClick={handleClose}>Ok</SecondaryButton>
                        </ModalFooter>
                    </div>
                </Modal>
            }
        </>
    );
}