import React from 'react';
import classes from './Static.module.css'
import StaticHeader from './StaticHeader';
import licenseAgreement from '../../assets/staticScreenAnimation/license-agreement.json'
import Divider from '../../components/Divider/Divider';
import StaticContent from './StaticContent';
import BackButton from '../../components/Buttons/BackButton';
import MainFooter from '../../components/MainFooter/MainFooter';

const content = [
    {
        title: "Lorem Ipsum",
        description: [
            "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.Duis Aute Irure Dolor In Reprehenderit In Voluptate Velit Esse Cillum Dolore Eu Fugiat Nulla Pariatur. Excepteur Sint Occaecat Cupidatat Non Proident, Sunt In Culpa Qui Officia Deserunt Mollit Anim Id Est Laborum."
        ]
    },
    {
        title: "Lorem Ipsum",
        description: [
            "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.Duis Aute Irure Dolor In Reprehenderit In Voluptate Velit Esse Cillum Dolore Eu Fugiat Nulla Pariatur. Excepteur Sint Occaecat Cupidatat Non Proident, Sunt In Culpa Qui Officia Deserunt Mollit Anim Id Est Laborum.",
            "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.Duis Aute Irure Dolor In Reprehenderit In Voluptate Velit Esse Cillum Dolore Eu Fugiat Nulla Pariatur. Excepteur Sint Occaecat Cupidatat Non Proident, Sunt In Culpa Qui Officia Deserunt Mollit Anim Id Est Laborum.",
            "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim Ad Minim Veniam, Quis Nostrud Exercitation Ullamco Laboris Nisi Ut Aliquip Ex Ea Commodo Consequat.Duis Aute Irure Dolor In Reprehenderit In Voluptate Velit Esse Cillum Dolore Eu Fugiat Nulla Pariatur. Excepteur Sint Occaecat Cupidatat Non Proident, Sunt In Culpa Qui Officia Deserunt Mollit Anim Id Est Laborum."
        ]
    }
]

function LicenseAgreement() {
    return (
        <>
            <div className={classes.main}>
                <div className={classes.backButtonWrapper}>
                    <BackButton className={classes.backButton} />
                </div>
                <div className={classes.container}>
                    <StaticHeader title={"License Agreement"} description={"Read our terms below to learn more"} animation={licenseAgreement} />
                    <Divider style={{ marginBottom: '2rem' }} />
                    <div className={classes.contentWrapper}>
                        {
                            content?.map((item, idx) => {
                                return <StaticContent key={idx} title={item.title} description={item.description} />
                            })
                        }
                    </div>
                </div>
            </div>
            <MainFooter />
        </>
    )
}

export default LicenseAgreement