import React from 'react';
import classes from './Static.module.css'

function StaticContent({title , description}) {
    return (
        <div className={classes.staticContentMain}>
            <h1 className={classes.staticContentHeading}>{title}</h1>
            {description && <div className={classes.staticContentDescription}>
                {
                    description?.map((para, idx) => {
                        return <p key={idx} className={classes.description}>{para}</p>
                    })
                }
            </div>}
        </div>
    )
}

export default StaticContent