import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import Utils from "../../Utils";
import classes from "./SignUp.module.css";
import { verifyUser, resendCode, forgotPassword, logout } from '../../modules/Users/UsersActions';
import signUpbackgroundImage from '../../assets/images/signup-background.svg';
import signUpBackgroundStars from '../../assets/images/signup-background-stars.svg';
import logoImage from "../../assets/bookalooza-logo.png";
import logoWithTagline from "../../assets/images/tagline.png";
import OTPForm from "./OTPForm";
import InfoForm from "./InfoForm";
import Consts from "../../Consts";
import { useDispatch } from "react-redux";


function otpVerification(data, history, postForgotPassword) {
    return verifyUser(data).then((response) => {
        if (response && response?.data?.success) {
            postForgotPassword();
        } else {
            throw {
                msg: 'Entered OTP is wrong'
            };
        }
    });
}

const infoConfig = {
    heading: "Forgot Password",
    subHeading: "Hey! enter your details to reset your password.",
}


export function ForgotPasswordForm({postForgotPassword, goToLogin}){
    const [userInfo, setUserInfo] = useState(null);
    const history = useHistory();
    const [otpError, setOTPError] = useState('');

    return (
        <div className={classes.leftMainBox}>
        <div className={classes.logoImage}>
            <img src={logoImage} className={classes.bulbImage} alt="Logo" />
            <img src={logoWithTagline} className={classes.tagline} alt="Tag line" />
        </div>
        <div className={classes.mainBox}>
            { !userInfo && <InfoForm isEmailValid={true}
                goToLogin={goToLogin}
                onSubmit={e => {
                    forgotPassword(e).then(d => {
                        if (d.success) {
                            setUserInfo(e);
                        }
                    });
                }}
                config={infoConfig}
            /> }
            { userInfo && <OTPForm 
                onCreate={e => 
                    otpVerification({
                        ...e,
                        ...userInfo
                    }, history,postForgotPassword).then(e => setOTPError(''))
                    .catch(err => setOTPError(err?.msg || 'Unknown'))
                }
                resendOTP={e => resendCode(userInfo)}
                onBack={e => setUserInfo(null)}
                errMsg={otpError}
                submitLabel="Update"
            />}
        </div>
    </div>
    )

}


function ForgotPassword(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (Utils.isUserLoggedIn()) {
            logout(history,dispatch);
        }
    }, []);

    function postForgotPassword(){
        history.push({
            pathname: Consts.BASE_URL || '/',
            state: {
                from: history.location.pathname
            }
        });
    }

    function goToLogin(){
        history.push({
			pathname: `${Consts.BASE_URL}/sign-in`,
			state: { ...history.location?.state },
		});
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.rightMainBox}>
                <img src={signUpbackgroundImage} className={classes.backgroundImage} />
                <img src={signUpBackgroundStars} className={classes.backgroundStars} />
            </div>
            <ForgotPasswordForm postForgotPassword={postForgotPassword} goToLogin={goToLogin}/>
        </div>
    );
}

export default ForgotPassword;