import React from "react";
import classes from './PanelButton.module.css';

function PanelButton({ state, showMenu, showTitle = true, onClick, children, className, title }) {
    const disabled = state?.disabled ? classes['x-item-disabled'] : '';
    const menu = showMenu ? classes['show-inner-menu'] : '';

    return (
        <div className={`${classes["panel-button"]} ${disabled} ${menu}`} onClick={onClick} title={title}>
            <React.Fragment>{children}</React.Fragment>
            <span className={className} />
            {
                showTitle &&
                <div className={classes["x-btn-inner"]}>{title}</div>
            }
            <div className={classes["x-btn-pointer"]}></div>
        </div>
    );
}

export default PanelButton;